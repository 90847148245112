/* eslint-disable no-unused-vars */
import React, { useMemo, useEffect, useState, useContext } from 'react';
import CN from 'classnames';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import useWalletHandler from 'utils/hooks/useWalletHandler';
import getToken from 'utils/auth/get-token';
import {
  getUserInfoClearEffect,
  isRequestUserInfoInProgress,
} from 'store/home/effects/user-info/get-user-info-effect';
import { selectTokenCountState } from 'features/app';
import { selectBugReportModal } from 'features/modals/modal-slice';
import { getNeyraUserInfo } from 'store/neyra/effects';
import { ThemeContext, LIGHT_THEME, DARK_THEME } from 'store/context/theme';

import { ReactComponent as DriveIcon } from './assets/drive.svg';
import { ReactComponent as LotusIcon } from './assets/lotus.svg';
import { ReactComponent as NeyraIcon } from './assets/neyra.svg';
import { ReactComponent as RecordIcon } from './assets/record.svg';
import { ReactComponent as SettingsIcon } from './assets/settings.svg';
// import { ReactComponent as TelegramIcon } from './assets/telegram.svg';
import { ReactComponent as WalletIcon } from './assets/wallet.svg';
import { ReactComponent as SunIcon } from './assets/sun.svg';
import { ReactComponent as MoonIcon } from './assets/moon.svg';
import Uploader from './Uploader';
import Notification from './Notification';
import BugReportMessageModal from 'containers/main/Main/FooterPanel/BugReportModal';
import SidebarFooter from 'containers/main/NeyraDriveContainer/components/SidebarFooter';

import TextToVoiceSpeaker from 'components/TextToVoiceSpeaker';
import { LoginWithEmailModal } from 'containers/auth/LoginWithEmailModal';
import useIsRegistrationFlow from 'utils/hooks/use-is-registration-flow';
import CommandLineTerminalWrapper from 'components/CommandLineTerminal/CommandLineTerminalWrapper';
import { MODAL_CONTAINER_ID } from 'containers/main/NeyraDriveContainer/components/ContentDrive/Template';

import { formatNumberToTokens } from 'utils/string';
import { isMobile } from 'utils/mobile';

import styles from './styles.module.scss';
import { isProductPage } from 'utils/url/is-page';

const circles = [1, 2, 3, 4];

const NeyraBorder = ({
  children,
  containerClass,
  contentClass,
  notification,
  renderSettings,
  sidebar,
  showBorder = true,
  isLoginPage = false,
  showCircles = true,
  uploader,
  initiateNeyraUser = true,
  renderNavigate,
  generateAiHandler,
  handleInputChange,
  isStudioDrawTab,
}) => {
  const isAuth = !!getToken();
  const dispatch = useDispatch();
  const tokenCountRedux = useSelector(selectTokenCountState);
  const isBugReportOpen = useSelector(selectBugReportModal);
  const uploadStatus = !!useSelector((store) => store.home.uploadFiles.entities)
    .length;
  const history = useHistory();
  const { handleClick } = useWalletHandler(history, 'chat');
  const [isEmailLogin, setIsEmailLogin] = useState(false);
  const { theme, setTheme } = useContext(ThemeContext);
  const light = theme === LIGHT_THEME;
  const isRegistrationFlow = useIsRegistrationFlow();
  const productPage = isProductPage();

  const changeTheme = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const nextTheme = theme === LIGHT_THEME ? DARK_THEME : LIGHT_THEME;
    setTheme(nextTheme);
  };

  const isZeroCredit = useMemo(
    () => isAuth && !+tokenCountRedux,
    [tokenCountRedux, isAuth]
  );

  useEffect(() => {
    if (location.pathname === '/') {
      setTheme(DARK_THEME);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (isAuth && !tokenCountRedux) {
      !isRequestUserInfoInProgress && dispatch(getUserInfoClearEffect());
      initiateNeyraUser && dispatch(getNeyraUserInfo());
    }
  }, [isAuth, tokenCountRedux, initiateNeyraUser]);

  const navigateOptions = [
    {
      name: 'chat',
      icon: <NeyraIcon />,
      action: () => {
        history.push('/chat');
      },
      ref: '/chat',
    },
    {
      name: 'drive',
      icon: <DriveIcon />,
      action: () => {
        history.push(isAuth ? '/main' : '/drive');
      },
      ref: isAuth ? '/main' : '/drive',
    },
    // {
    //   name: 'file',
    //   icon: <FileIcon />,
    //   action: () => {},
    // },
    {
      name: 'neyra',
      icon: <LotusIcon />,
      action: () => {
        history.push(isAuth ? '/studio' : '/bios');
      },
      className: styles.lotusBtn,
      ref: '/studio',
    },
    // {
    //   name: 'neyra',
    //   icon: <NeyraIcon />,
    //   action: () => {
    //     history.push('/ring');
    //   },
    //   ref: '/ring',
    // },
    {
      name: 'settings',
      icon: <SettingsIcon />,
      action: () => {},
      className: styles.settingsBtn,
    },
  ];

  const actionOptions = useMemo(() => {
    const options = [
      {
        name: 'record',
        icon: <RecordIcon />,
        action: () => {},
      },
      {
        name: 'speaker',
        icon: <TextToVoiceSpeaker />,
        action: () => {},
        isComponent: true,
      },
      {
        name: 'settings',
        icon: <SettingsIcon />,
        action: () => {
          isAuth && history.push('/account/settings');
        },
      },
    ];
    if (isAuth)
      options.push({
        name: 'theme',
        icon: light ? <MoonIcon /> : <SunIcon />,
        action: (e) => {
          changeTheme(e);
        },
      });
    return options;
  }, [isAuth, light]);

  const connectList = [
    // {
    //   name: 'telegram',
    //   icon: <TelegramIcon />,
    //   action: () => {},
    // },
    // {
    //   name: 'email',
    //   icon: <EmailIcon />,
    //   action: () => {
    //     setIsEmailLogin(true);
    //   },
    // },
    {
      name: 'wallet',
      icon: <WalletIcon />,
      action: () => {
        handleClick('wallet');
      },
    },
  ];

  const settingsList = renderSettings
    ? renderSettings(actionOptions)
    : isAuth && location.pathname !== '/sign-in'
    ? actionOptions
    : isAuth
    ? actionOptions.slice(2)
    : [];

  const onTokensReirect = () => {
    isAuth ? history.push('/wallet/buy-credits') : history.push('/token');
  };

  const activeButton = useMemo(() => {
    const currentRoute = location.pathname;
    return currentRoute === '/token' || currentRoute === '/wallet/buy-credits';
  }, [location.pathname]);

  if (!showBorder) return children;

  const navigateList = renderNavigate
    ? renderNavigate(navigateOptions)
    : navigateOptions;

  const isNotSupportingTheTerminal =
    location.pathname === '/' || isRegistrationFlow || !isAuth;

  return (
    <div
      className={CN(
        styles.container,
        productPage && styles.containerProducts,
        containerClass
      )}
    >
      <div className={CN(styles.content, contentClass)} id={MODAL_CONTAINER_ID}>
        {!productPage &&
          showCircles &&
          circles.map((circle) => (
            <div
              key={circle}
              className={`${styles.cube} ${styles[`circle${circle}`]}`}
            >
              <div className={`${styles.circle}`}></div>
            </div>
          ))}
        {children}
        {isEmailLogin && (
          <LoginWithEmailModal
            setIsEmailLogin={setIsEmailLogin}
            isEmailLogin={isEmailLogin}
          />
        )}
      </div>
      {sidebar}
      <footer
        className={CN(
          styles.footer,
          !isNotSupportingTheTerminal && styles.smallerFooter
        )}
      >
        {isNotSupportingTheTerminal ? (
          <>
            <div className={styles.footerNavigate}>
              {navigateList.map(
                ({ name, icon, action, ref = '', className = '' }) => (
                  <button
                    disabled={isRegistrationFlow}
                    className={CN(
                      styles.footerNavigateBtn,
                      ref === location.pathname &&
                        styles.footerNavigateBtnActive,
                      className
                    )}
                    key={name}
                    onClick={action}
                  >
                    {icon}
                  </button>
                )
              )}
              {isMobile && isRegistrationFlow && (
                <button
                  disabled={isRegistrationFlow}
                  onClick={onTokensReirect}
                  className={CN(
                    styles.footerActionsTokens,
                    activeButton &&
                      !isRegistrationFlow &&
                      styles.activeButtonToken,
                    isZeroCredit && styles.warningButtonToken
                  )}
                >
                  <span className={styles.footerActionsTokensСircle} />
                  <span className={styles.footerActionsTokensText}>
                    {formatNumberToTokens(
                      Math.round(parseFloat(tokenCountRedux)) || 0,
                      0
                    )}
                  </span>
                </button>
              )}
              {uploadStatus && uploader && <Uploader />}
              {notification?.text && <Notification {...notification} />}
            </div>
            {(isLoginPage || !isAuth) && (
              <div
                className={CN(styles.footerNavigate, styles.loginPageButton)}
              >
                {connectList.map(({ name, icon, action, className = '' }) => (
                  <button
                    disabled={isRegistrationFlow}
                    className={CN(styles.footerActionsBtn, className)}
                    key={name}
                    onClick={action}
                  >
                    {icon}
                  </button>
                ))}
              </div>
            )}
            <div className={styles.footerActions}>
              {settingsList.map(
                ({ name, icon, action, className = '', isComponent = false }) =>
                  isComponent ? (
                    <div key={name}>{icon}</div>
                  ) : (
                    <button
                      disabled={isRegistrationFlow}
                      className={CN(styles.footerActionsBtn, className)}
                      key={name}
                      onClick={action}
                    >
                      {icon}
                    </button>
                  )
              )}
              <button
                disabled={isRegistrationFlow}
                onClick={onTokensReirect}
                className={CN(
                  styles.footerActionsTokens,
                  activeButton &&
                    !isRegistrationFlow &&
                    styles.activeButtonToken,
                  isZeroCredit && styles.warningButtonToken
                )}
              >
                <span className={styles.footerActionsTokensСircle} />
                <span className={styles.footerActionsTokensText}>
                  {formatNumberToTokens(
                    Math.round(parseFloat(tokenCountRedux)) || 0,
                    0
                  )}
                </span>
              </button>
            </div>
          </>
        ) : (
          <CommandLineTerminalWrapper
            generateAiHandler={generateAiHandler}
            handleInputChange={handleInputChange}
            isStudioDrawTab={isStudioDrawTab}
          />
        )}
        {productPage && <SidebarFooter className={styles.sdebarFooter} />}
      </footer>
      <BugReportMessageModal isOpen={isBugReportOpen} />
    </div>
  );
};

export default NeyraBorder;
