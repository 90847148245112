/* eslint-disable no-unused-vars */
import React, { useMemo, useContext, useEffect, useState } from 'react';
import CN from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector, connect } from 'react-redux';
import { useHistory } from 'react-router';

import NeyraBorder from 'components/NeyraBorder';
import NeyraSidebar from 'components/NeyraSidebar';
import MemosSidebar from 'components/MemosSidebar';
import LinkSidebar from 'containers/link/components/LinkSidebar';
import SpaceSidebar from 'containers/account/Space/SpaceSidebar';
import SidebarItem from 'containers/main/NeyraDriveContainer/components/SidebarItem';
import FileInfoSidebar from 'containers/main/NeyraDriveContainer/components/FileInfoSidebar';
import Content from 'containers/main/NeyraDriveContainer/components/Content';
import SidebarFooter from 'containers/main/NeyraDriveContainer/components/SidebarFooter';
import ContentDrive from 'containers/main/NeyraDriveContainer/components/ContentDrive';

import { ReactComponent as DriveIcon } from 'containers/main/Main/components/MainSidebar/components/Container/assets/v2/drive.svg';
import { ReactComponent as FileIcon } from 'containers/main/Main/components/MainSidebar/components/Container/assets/v2/files.svg';
import { ReactComponent as CommentsIcon } from 'containers/main/Main/components/MainSidebar/components/Container/assets/v2/comments.svg';
import { ReactComponent as DeletedIcon } from 'containers/main/Main/components/MainSidebar/components/Container/assets/v2/deleted.svg';
import { ReactComponent as LinksIcon } from 'containers/main/Main/components/MainSidebar/components/Container/assets/v2/links.svg';
import { ReactComponent as MemoIcon } from 'containers/main/Main/components/MainSidebar/components/Container/assets/v2/memo.svg';
import { ReactComponent as RecentIcon } from 'containers/main/Main/components/MainSidebar/components/Container/assets/v2/recent.svg';
import { ReactComponent as ShareIcon } from 'containers/main/Main/components/MainSidebar/components/Container/assets/v2/share.svg';
import { ReactComponent as StareIcon } from 'containers/main/Main/components/MainSidebar/components/Container/assets/v2/star.svg';

import { ReactComponent as VideoIcon } from 'containers/main/Main/components/MainSidebar/components/Container/assets/v2/video.svg';
import { ReactComponent as AudioIcon } from 'containers/main/Main/components/MainSidebar/components/Container/assets/v2/audio.svg';
import { ReactComponent as DocumentsIcon } from 'containers/main/Main/components/MainSidebar/components/Container/assets/v2/documents.svg';
import { ReactComponent as PicturesIcon } from 'containers/main/Main/components/MainSidebar/components/Container/assets/v2/pictures.svg';
import { ReactComponent as TokenizeIcon } from 'containers/main/Main/components/MainSidebar/components/Container/assets/v2/tokenized.svg';
import { ReactComponent as SettingsIcon } from 'containers/main/Main/components/MainSidebar/components/Container/assets/v2/settings2.svg';

import { selectedEntityClearEffect } from 'store/home/effects/selected-entity/selected-entity-clear-effect';
import fileFolderActionHandlerEffect from 'store/home/effects/entity-actions/entity-action-handler.effect';
import { fileUpdateDataEffect } from 'store/home/effects/file/file-update.effect';
import { fileUpdateDataSelectedEntityEffect } from 'store/home/effects/entity-actions/entity-make-action.effect';
import { isProductPage } from 'utils/url/is-page';

import MainContext from 'store/main/context/main-context';

import styles from './styles.module.scss';

const checkEmptyList = ['video', 'audio', 'pictures', 'documents', 'tokenized'];

const SettingsContainer = ({ selectedEntity, children, isGuest }) => {
  const history = useHistory();
  const fileCountByType = useSelector(
    (store) => store.workspaceSettings.fileCountByType
  );
  const fileCountByTypeOld = useSelector(
    (state) => state.home?.getFiles?.count
  );
  const { t } = useTranslation('sidebar');
  const collapsed = !!selectedEntity;
  const {
    state: { isDeletedPage, isAiGeneratorPage },
  } = useContext(MainContext);
  const productPage = isProductPage();

  const MainMenu = useMemo(
    () => [
      {
        collapsed: collapsed,
        icon: <DriveIcon />,
        name: t('accountSettings.drive'),
        action: () => {
          history.push('/main');
        },
        backIcon: <SettingsIcon />,
        backButton: true,
        isMain: true,
        backOnClick: () => {
          history.push('/workspace/settings');
        },
        tooltipText: 'Drive Settings',
      },
      {
        icon: <FileIcon />,
        name: t('mainSidebar.files'),
        url: '/main',
        count: fileCountByType.total,
      },
      {
        icon: <StareIcon />,
        name: t('mainSidebar.myAI'),
        url: '/dataset',
        count: fileCountByType.starred,
      },
      {
        key: 'tokenized',
        url: '/tokenized-files',
        name: t('mainSidebar.tokenized'),
        icon: <TokenizeIcon />,
        count: fileCountByType.tokenized,
      },
      {
        key: 'memos',
        icon: <MemoIcon />,
        url: '/memos',
        name: t('mainSidebar.memos'),
        count: fileCountByType.memos,
      },
      {
        key: 'documents',
        url: '/documents',
        name: t('mainSidebar.doc'),
        icon: <DocumentsIcon />,
        count: fileCountByType.docs,
      },
      {
        key: 'video',
        url: '/video',
        name: t('mainSidebar.video'),
        icon: <VideoIcon />,
        count: fileCountByType.videos,
      },
      {
        key: 'audio',
        url: '/audio',
        name: t('mainSidebar.audio'),
        icon: <AudioIcon />,
        count: fileCountByType.audios,
      },
      {
        key: 'pictures',
        url: '/pictures',
        name: t('mainSidebar.pictures'),
        icon: <PicturesIcon />,
        count: fileCountByType.images,
      },
      {
        key: 'links',
        url: '/links',
        name: t('mainSidebar.links'),
        icon: <LinksIcon />,
        count: fileCountByType.links,
      },
      // {
      //   icon: <EventsIcon />,
      //   name: t('mainSidebar.events'),
      //   url: '/automation',
      // },
      {
        icon: <CommentsIcon />,
        name: t('mainSidebar.comments'),
        url: '/comments',
      },
      {
        icon: <ShareIcon />,
        name: t('mainSidebar.shared'),
        url: '/sharing',
        count: fileCountByType.shared,
      },
      {
        icon: <RecentIcon />,
        name: t('mainSidebar.recent'),
        url: '/recent',
      },
      {
        icon: <DeletedIcon />,
        name: t('mainSidebar.deleted'),
        url: '/deleted',
        count: fileCountByType.deleted,
      },
      // {
      //   key: 'notification',
      //   icon: <NotificationIcon />,
      //   name: t('mainSidebar.notifications'),
      // },
    ],
    [fileCountByType]
  ).filter((item) => {
    return checkEmptyList.includes(item.key) ? !!item.count : true;
  });

  const GuestMenu = useMemo(
    () => [
      {
        icon: <ShareIcon />,
        name: t('mainSidebar.shared'),
        url: '/sharing',
      },
      {
        icon: <RecentIcon />,
        name: t('mainSidebar.recent'),
        url: '/recent',
      },
      // {
      //   icon: <NotificationIcon />,
      //   name: t('mainSidebar.notifications'),
      // },
    ],
    []
  );
  const list = isGuest ? GuestMenu : MainMenu;
  const isPageEditor =
    location.pathname.includes('/memo/') ||
    location.pathname.includes('/memos');
  const isLinkPage =
    location.pathname.includes('/links') ||
    location.pathname.includes('/link/');
  const isSpacePage = location.pathname.includes('/space');

  const [prevLocation, setPrevLocation] = useState('');

  useEffect(() => {
    if (!location.pathname.includes('/memo/')) {
      setPrevLocation(location.pathname);
    }
  }, [location.pathname]);

  const renderSidebar = () => {
    if (isSpacePage) {
      return <SpaceSidebar />;
    }

    if (isLinkPage) {
      return <LinkSidebar />;
    }

    if (isPageEditor)
      return <MemosSidebar prevLocation={prevLocation} collapsed={collapsed} />;

    if (selectedEntity && !isDeletedPage) return <FileInfoSidebar />;

    return (
      <>
        {list.map((item, index) => (
          <SidebarItem
            key={`settings-item-${index}`}
            item={{ ...item, collapsed: collapsed }}
          />
        ))}
      </>
    );
  };

  return (
    <div className={styles.settingsContainer}>
      <NeyraBorder uploader>
        <ContentDrive>{children}</ContentDrive>
      </NeyraBorder>
      {!isAiGeneratorPage && !productPage && (
        <NeyraSidebar
          className={CN(
            collapsed && styles.settingsContentColapsed,
            styles.settingsContent
          )}
        >
          {selectedEntity && !isDeletedPage && (
            <Content selectedEntity={selectedEntity} />
          )}
          <div
            className={CN(
              styles.siteSidebar,
              collapsed && styles.siteSidebarCollapsed
            )}
          >
            <div
              className={CN(
                styles.settingsList,
                collapsed && styles.settingsListCollapsed
              )}
            >
              {renderSidebar()}
            </div>
            <SidebarFooter isCollapsed={collapsed} />
          </div>
        </NeyraSidebar>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  selectedEntity: state.home?.selectedEntity?.entity,
  entityView: state.home.selectedEntity.entityView,
});

const mapDispatchToProps = {
  fileUpdateDataSelectedEntityEffect,
  selectedEntityClearEffect,
  fileFolderActionHandlerEffect,
  fileUpdateDataEffect,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsContainer);
