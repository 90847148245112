/* eslint-disable no-debugger */
//Refactoring №3
import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import RSC from 'react-scrollbars-custom';
import { useDispatch } from 'react-redux';
import SortableTree from 'react-sortable-tree-patch-react-17';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import { useTranslation } from 'react-i18next';

import { useKeyPress } from 'ahooks';
import { isEmpty } from 'lodash';
import { getShortenedName, isFile } from 'utils/file';

import { handleMoveModal } from 'features/modals/modal-slice';
import fileFolderActionHandlerEffect from 'store/home/effects/entity-actions/entity-action-handler.effect';
import { getFolderTreeEffect } from 'containers/main/Main/components/MoveModal/effects/get-folders.effect.js';
import actionsOptions from 'config/actions-options';
import { entitiesTypes } from 'config/entities-types';

import useNotification from 'utils/hooks/use-notification';
import CloudSvgImage from 'components/svg/cloud';
import TemplateForm from "containers/main/NeyraDriveContainer/components/ContentDrive/Template";
import Tooltip from 'components/Tooltip';
import Button from '../Template/Button';

import { ReactComponent as EmptyFile } from 'containers/main/Main/components/MoveModal/assets/empty-file.svg';
import SingleFolder from 'containers/main/Main/components/MoveModal/assets/single-folder.js';

import CN from 'classnames';
import style from './styles.module.scss';

class ExtendedTreeNodeRenderer extends React.Component {
  render() {
    const { path, node, treeIndex, ...otherProps } = this.props;
    const TreeNodeRenderer = FileExplorerTheme.treeNodeRenderer;
    const nodeClassName = CN({
      [style['tree-folder']]: true,
      [style['tree-folder-selected']]: path.includes(`${node.slug}-selected`),
    });

    return (
      <div className={nodeClassName}>
        <TreeNodeRenderer
          {...otherProps}
          node={node}
          path={path}
          treeIndex={treeIndex}
        />
      </div>
    );
  }
}

export const ExtendedFileExplorerTheme = {
  ...FileExplorerTheme,
  treeNodeRenderer: ExtendedTreeNodeRenderer,
};

const MoveModal = ({ entities, dispatch }: any) => {
  const { t } = useTranslation('contextMenu');
  const [currentEntities, setCurrentEntities] = useState([]);
  const [currentNode, setCurrentNode] = useState(null);
  const [treeData, setTreeData] = useState([]);
  const { addNotification } = useNotification();
  const containerRef = useRef();
  const itemsRef = useRef([]);

  useEffect(() => {
    if (entities?.length) {
      setCurrentEntities([...entities]);
    }
  }, [entities]);

  useLayoutEffect(() => {
    const parentRect = containerRef?.current?.getBoundingClientRect();
    const sizes = itemsRef?.current?.map(
      (el) =>
        el?.getBoundingClientRect().right + el?.getBoundingClientRect().width
    );
    let maxSize = parentRect?.right;
    sizes.map((el) => {
      if (el) {
        if (el > parentRect?.right) {
          maxSize = el;
        }
      }
    });
    document
      .querySelector('div[role="rowgroup"]')
      ?.scrollTo({ left: maxSize, top: -10000, behavior: 'smooth' });
  }, [treeData]);

  useKeyPress(['enter'], () => {
    moveEntities();
  });

  useEffect(() => {
    if (!isEmpty(currentEntities)) {
      getFolderTreeEffect(currentEntities.map((item) => item.slug)).then(
        (data) => {
          const finalData = [{ title: 'Drive', slug: null }, ...data];
          setTreeData(finalData);
        }
      );
    }
  }, [currentEntities]);

  const closeModal = () => {
    dispatch(handleMoveModal({ isOpen: false, entity: null }));
  };

  const moveEntities = () => {
    if (currentNode) {
      const update = {};
      const updatedPropsList = [];

      update.folder = currentNode.node.slug ? currentNode.node.slug : null;
      updatedPropsList.push('folder');

      dispatch(fileFolderActionHandlerEffect(
        currentEntities,
        actionsOptions.move,
        update.folder
      ));
      closeModal();
      const isFile = currentEntities.some(
        (entity) => entity.type === entitiesTypes.file.id
      );
      addNotification(
        `${isFile ? t('files.move.file') : t('files.move.folder')}${t(
          'files.move.successfullyMoved'
        )} ${currentNode.node.name ? currentNode.node.name : 'Drive'}`
      );
    }
  };

  let entitiesContent;
  const currentEntitiesAmount = currentEntities.length;

  const objectsSelected = currentEntitiesAmount ? (
    <div className={style.moveLeftTitle}>
      {t('files.move.singleSelected', { currentEntitiesAmount })}
    </div>
  ) : null;

  if (currentEntitiesAmount === 1) {
    const entity = currentEntities[0];
    const color = entity?.color?.[entity.color.length - 1];
    const colorHex = color?.hex;
    const entityImage =
      entity.type === entitiesTypes.folder.id ? (
        <SingleFolder color={colorHex} />
      ) : (
        <EmptyFile />
      );

    // moveTitle =
    //   entity.type === entitiesTypes.file.id
    //     ? t('files.move.file')
    //     : t('files.move.folder');

    entitiesContent = (
      <div className={CN(style.moveLeft, style.moveLeftSingle)}>
        <div className={style.moveLeftItemTitle}>{objectsSelected}</div>
        <div className={style.moveLeftItem}>
          <div className={style.moveLeftItemImage}>{entityImage}</div>
          <div className={style.moveLeftItemName}>
            {getShortenedName(entity.name)}
          </div>
        </div>
      </div>
    );
  } else {
    const currentEntitiesContent = currentEntities.map((entity) => {
      const color = entity?.color?.[entity.color.length - 1];
      const colorHex = color?.hex;
      const entityImage =
        entity.type === entitiesTypes.folder.id ? (
          <SingleFolder color={colorHex} />
        ) : (
          <EmptyFile />
        );
      return (
        <div
          className={CN(style.moveLeftItem, style.moveLeftMultiple)}
          key={`move-item-${entity.slug}`}
        >
          <div className={style.moveLeftItemImage}>{entityImage}</div>
          <div className={style.moveLeftItemName}>
            {getShortenedName(entity.name)}
          </div>
        </div>
      );
    });

    // moveTitle = currentEntities.every(
    //   (entity) => entity.type === entitiesTypes.file.id
    // )
    //   ? t('files.move.files')
    //   : t('files.move.folders');

    entitiesContent = (
      <div className={style.moveLeft}>
        {objectsSelected}
        <RSC
          style={{ width: 222, height: 314 }}
          thumbYProps={{
            style: { background: '#206ACF', width: '1px', height: '60px' },
          }}
          trackYProps={{ style: { background: 'none', width: '1px' } }}
        >
          {currentEntitiesContent}
        </RSC>
      </div>
    );
  }

  const moveToTitle = (
    <div className={style.moveRightTitle}>
      {t('files.move.moveTo') + ':'}
      <span className={style.moveRightTitleHighlight}>
        {currentNode?.node?.name || 'Drive'}
      </span>
    </div>
  );

  const updateTreeData = (data) => {
    setTreeData(data);
  };

  const onNodeClick = (rowInfo) => () => {
    const {
      node: { slug, ...restNode },
    } = rowInfo;

    const moveToFolderSlug = slug || 'main';

    const isCurrentParent = (item) => item.folderId === moveToFolderSlug;
    const isCurrentFolder = (item) => item.slug === moveToFolderSlug;
    const isTokenized = restNode?.entry_groups?.some(
      (item) => item.is_tokenized
    );

    const allowedToMove = !currentEntities.find(
      (item) => isCurrentParent(item) || isCurrentFolder(item)
    );

    if (allowedToMove && !isTokenized) {
      setCurrentNode(rowInfo);
    } else {
      setCurrentNode(null);
    }
  };

  const treeContent = (
    <div ref={containerRef} className={style.moveRight}>
      {moveToTitle}
      <SortableTree
        treeData={treeData}
        onChange={updateTreeData}
        theme={ExtendedFileExplorerTheme}
        canDrag={false}
        canDrop={() => false}
        rowHeight={31}
        getNodeKey={({ node, treeIndex }) => {
          const isActive = currentNode?.treeIndex === treeIndex;
          return isActive ? `${node.slug}-selected` : node.slug;
        }}
        generateNodeProps={(rowInfo) => ({
          onClick: onNodeClick(rowInfo),
          title: ({ treeIndex, node }) => {
            const isSelected = currentNode?.treeIndex === treeIndex;
            const color = isSelected ? '#007AFF' : '#CCCEDA';
            const isDrive = node.title == 'Drive';
            const isTokenized = node?.entry_groups?.some(
              (item) => item.is_tokenized
            );
            return (
              <Tooltip
                placement="top"
                overlay={
                  <p className={style.hint}>
                    {t('files.move.folderTokenized')}
                  </p>
                }
                trigger={['hover']}
                defaultVisible
                showArrow={false}
                visible={isTokenized ? undefined : false}
              >
                <span
                  className={CN(style.folderInTree, {
                    [style.selectedFolder]: isSelected,
                    [style.folderInTreeDisabled]: isTokenized,
                  })}
                  ref={(el) => (itemsRef.current[treeIndex] = el)}
                >
                  {isDrive ? (
                    <CloudSvgImage color={color} />
                  ) : (
                    <SingleFolder color={color} isSelected={true} />
                  )}
                  {node.title}
                </span>
              </Tooltip>
            );
          },
        })}
        currentNode={currentNode}
      />
    </div>
  );

  return (
    <section className={style.modal}>
      <div className={style.modalContent}>
        {entitiesContent}
        {treeContent}
      </div>
      <div className={style.modalBtn}>
        <Button
          onClick={moveEntities}
          disabled={!currentNode}
        >
          {t('files.move.move')}
        </Button>
      </div>
    </section>
  );
};

const CreateRenameModal = ({ entity, ...rest }: any) => {
  const { t } = useTranslation('contextMenu');
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(handleMoveModal({ isOpen: false, entity: null }));
  }

  return (
    <TemplateForm
      onClose={onClose}
      title={`${t('files.move.move')} ${isFile(entity[0]) ? t('files.move.file') : t('files.move.folder')} `}
    >
      <MoveModal
        {...rest}
        entities={entity}
        dispatch={dispatch}
      />
    </TemplateForm>
  )
}

export default CreateRenameModal;
