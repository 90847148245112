// Refactoring №3
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  imageMediaTypes,
  imageMediaTypesPreview,
  imagesWithoutPreview,
} from 'config/image-file-extensions';
import {
  audioFileExtensions,
  audioMediaTypes,
} from 'config/audio-file-extensions';
import {
  videoMediaExtentionContent,
  videoMediaExtentionPreview,
  videoWithoutPreview,
} from 'config/video-file-extensions';
import {
  docMediaTypesContent,
  docMediaWithoutPreview,
} from 'config/docs-file-extensions';

import useClickOutside from 'utils/hooks/use-click-outside';

import ImageContent from './image-content';
import PDFContent from './pdf-content';
import AudioStream from './audio-stream';
import VideoViewer from './video-viewer';
import DefaultContent from './default-content';
import ExcelViewer from './excel-viewer';
import TxtViewer from './txt-viewer';
import { ONE_MB, MAX_PREVIEW_SIZE } from 'config/file-action-types';

const FileContent = ({
  file,
  handleOnClose,
  entityContent,
  scrollElementRef,
  onClickOutSide,
  footerContent,
  isSvg = false,
}) => {
  const [entityType, setEntityType] = useState('');
  const fileContentRef = useRef(null);
  const previewUpdateDay = 1689920400;
  useClickOutside(fileContentRef, onClickOutSide);
  const { t } = useTranslation('owner');
  const size = Number((file.size / ONE_MB).toFixed(1));
  let entityFileType;

  if (file?.is_clientside_encrypted) entityFileType = 'encrypt';
  else {
    if (videoMediaExtentionPreview.includes(file.extension) && !entityContent) {
      entityFileType = 'video';
    }
    if (file.mime && entityContent) {
      if (videoMediaExtentionPreview.includes(file.extension)) {
        entityFileType = 'video';
        if (videoWithoutPreview.includes(file.extension)) {
          entityFileType = '';
        }
      } else if (audioMediaTypes.includes(file.mime)) {
        entityFileType = 'audio';
      } else if (audioFileExtensions.includes(`.${file.extension}`)) {
        entityContent = '';
      } else if (file.mime === 'application/pdf') {
        entityFileType = 'pdf';
      } else if (
        docMediaTypesContent.includes(file.mime) ||
        docMediaWithoutPreview.includes(file.extension)
      ) {
        entityFileType = 'document';
        if (docMediaWithoutPreview.includes(file.extension)) {
          entityFileType = '';
        }
      } else if (imageMediaTypes.includes(file.mime)) {
        if (imagesWithoutPreview.includes(`.${file.extension}`)) {
          entityContent = '';
        } else {
          entityFileType = 'img';
        }
      } else if (file.extension === 'xlsx' || file.extension === 'xls') {
        entityFileType = 'xlsx';
      } else if (file.mime === 'text/plain') {
        entityFileType = 'txt';
      }
    }
  }

  const defaultTextGenerator = () => {
    if (
      videoMediaExtentionContent.includes(file.extension.toLowerCase()) &&
      !file.ipfs_hash
    ) {
      return t('files.videoContentError');
    }
    return '';
  };

  if (file.is_on_storage_provider && size >= MAX_PREVIEW_SIZE) {
    return (
      <DefaultContent
        file={file}
        fileContentRef={fileContentRef}
        text={defaultTextGenerator()}
      />
    );
  }
  if (entityFileType === 'img' && file.created_at < previewUpdateDay) {
    return (
      <DefaultContent
        file={file}
        fileContentRef={fileContentRef}
        text={defaultTextGenerator()}
      />
    );
  }
  switch (entityType || entityFileType) {
    case 'encrypt':
      return !imageMediaTypesPreview.includes(file.mime) ||
        imagesWithoutPreview.includes(`.${file.extension}`) ||
        !entityContent ? (
        <DefaultContent
          file={file}
          fileContentRef={fileContentRef}
          text={t('files.unavailableContent')}
          isEncrypt
        />
      ) : (
        <ImageContent
          file={file}
          entityContent={entityContent}
          fileContentRef={fileContentRef}
          isSvg={isSvg}
          footerContent={footerContent}
        />
      );
    case 'img':
      return (
        <ImageContent
          file={file}
          entityContent={entityContent}
          fileContentRef={fileContentRef}
          isSvg={isSvg}
          footerContent={footerContent}
        />
      );

    case 'pdf':
    case 'document':
      return (
        <PDFContent
          entity={file}
          setEntityType={setEntityType}
          handleOnClose={handleOnClose}
          fileContentRef={fileContentRef}
          entityContent={entityContent}
          scrollElementRef={scrollElementRef}
          defaultTextGenerator={defaultTextGenerator}
        />
      );

    case 'audio':
      return <AudioStream file={file} fileContentRef={fileContentRef} />;

    case 'video':
      return <VideoViewer file={file} />;
    case 'xlsx':
      return <ExcelViewer file={file} setEntityType={setEntityType} />;
    case 'txt':
      return <TxtViewer file={file} setEntityType={setEntityType} />;

    default:
      return (
        <DefaultContent
          file={file}
          fileContentRef={fileContentRef}
          text={defaultTextGenerator()}
        />
      );
  }
};

export default FileContent;
