// Refactoring №3
// Utils
import authRequest from 'utils/request/auth-request';

// Store
import * as actions from '../../actions/file/delete-file.actions';
import * as getFilesActions from '../../actions/files/get-files.actions';
import { removeDocumentSuccess } from '../../actions/documents.actions';
import { removeVaultSuccess } from 'store/home/actions/vaults.actions';
import { API_GET_FILE } from 'constants/api-urls';
import { getUserInfoEffect } from '../user-info/get-user-info-effect';
import { workspaceSetFileCountByType } from 'features/workspaceSettings/workspaceSettingsSlice';
import { getWorkspaceFileCount } from 'store/workspace/effects';

export default async (files, dispatch, additionalProps) =>
  await authRequest
    .delete(
      `${API_GET_FILE}/multiply/delete`,
      files.map((file) => file.slug)
    )
    .then(() => {
      if (additionalProps.isDocumentPage) {
        dispatch(removeDocumentSuccess(files));
      } else if (additionalProps.isVaultPage) {
        dispatch(removeVaultSuccess(files));
      } else {
        dispatch(getFilesActions.getFilesRemove(files));
      }
      getWorkspaceFileCount().then((data) => {
        dispatch(workspaceSetFileCountByType(data));
      });
      dispatch(getUserInfoEffect());
    })
    .catch((e) => {
      dispatch(actions.fileDeleteFailure());
      throw e;
    });
