// Refactoring №2
import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  AudioPlayer,
  AudioSVGImage,
  AudioController,
} from 'fe-ui-kit/dist/index.esm';
import 'fe-ui-kit/dist/styles.css';
import s from './style.module.scss';
import { useTranslation } from 'react-i18next';
import GhostLoader from 'components/GhostLoader';
import { sendFileViewStatistic } from 'store/home/effects/statistic/file-statistic-effect';
import { GHOST_DRIVE_API } from 'constants/api-urls';
import { getDecryptionKey } from 'utils/file/getDecryptKey';

const AudioStream = ({ file }) => {
  const { t: accountT } = useTranslation('account');
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [decryptionKey, setDecryptionKey] = useState('');

  const onLoadedMetadata = () => {
    setIsLoaded(true);
    sendFileViewStatistic(file.slug);
  };

  const onError = (err) => {
    setIsLoaded(true);
    setError(err);
  };

  useEffect(() => {
    if (file.is_clientside_encrypted) {
      getDecryptionKey(file).then((key) => {
        key ? setDecryptionKey(key) : onError('Error fetching decryption key');
      });
    }
  }, [file.is_clientside_encrypted]);

  const audioRef = useRef(null);

  const renderVideo = useCallback(() => {
    if (file.is_clientside_encrypted && !decryptionKey) {
      return null;
    }

    if (file.slug) {
      return (
        <div
          role="group"
          aria-label="Audio player"
          className={`rhap_container ${
            isLoaded ? s.mediaVisible : s.mediaInvisible
          }`}
        >
          <AudioSVGImage className="file-content-audio-image" />
          <AudioPlayer
            slug={file.slug}
            apiUrl={GHOST_DRIVE_API}
            decryptionKey={decryptionKey}
            onError={onError}
            onLoadedMetadata={onLoadedMetadata}
            audioRef={audioRef}
          />
          <AudioController audioRef={audioRef} />
        </div>
      );
    }
  }, [file.slug, decryptionKey, file.is_clientside_encrypted, isLoaded]);

  return (
    <div className="file-view__audio-container">
      <div className="file-content-image-container file-content-image-container-default-column audio-player">
        {error ? <div className="error">{error}</div> : renderVideo()}
        {!isLoaded && (
          <div className={`loader ${s.absolute}`}>
            <GhostLoader
              texts={[
                accountT('convertModal.loading'),
                accountT('convertModal.pleaseWait'),
              ]}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AudioStream;
