//Refactoring №3
import React, { useState, useContext, useEffect, FC, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Modal from 'react-modal';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { isEmpty } from 'ramda';
import moment from 'moment';
import classNames from 'classnames';
import { isMobile as mobile } from 'utils/mobile';

import CreateEntityModal from './create-entity-modal';
import ActionButtons from './components/ActionButtonsV2';
import RecordModal from 'components/popups/RecordModal';
import VaultModal from 'components/VaultModal';
import LinkModal from 'components/LinkModal';
import NotificationsHeader from './notifications-header';
import { ReactComponent as Burger } from './assets/burger.svg';

import { Sidebar } from 'containers/main/Main/Sidebar';
import ActionSettingButtons from 'containers/header/header-container/components/ActionSettingButtons';

import MainContext from 'store/main/context/main-context';
import { createDocumentSuccess } from 'store/home/actions/documents.actions';
import {
  getFilesUpdateList,
  getFilesClear,
} from 'store/home/actions/files/get-files.actions';
import {
  createFolderEffect,
  getUserInfoEffect,
  getFilesDispatchEffect,
} from 'store/home/effects';
import setFileViewTypeEffect from 'store/home/effects/file-view-type/set-file-view-type-effect';
import fileFolderActionHandlerEffect from 'store/home/effects/entity-actions/entity-action-handler.effect';
import { selectedEntityClearEffect } from 'store/home/effects/selected-entity/selected-entity-clear-effect';
import { selectedFilesRemoveEffect } from 'store/home/effects/selected-entity/selected-files-remove-effect';
import { pendingFilesRemove } from 'store/home/actions/pending-files-folders.actions';
import { selectedFilesRemove } from 'store/home/actions/selected-files-folders.actions';
import * as getFilesActions from 'store/home/actions/files/get-files.actions';
import { setErrors } from 'store/errors/actions/index';
import deleteMultiFiles from 'store/home/effects/file/delete-multi-file';
import { saveMemoEffect } from 'store/document/effects';
import EntitySettings from 'store/home/contexts/entity-settings';
import {
  handleCreateDocumentModal,
  handleCreateFolderModal,
  selectVaultModal,
  selectLinkModal,
  handleDeleteModal,
} from 'features/modals/modal-slice';

import {
  selectCollapsed,
  setSidebarVisibility,
} from 'features/leftMenu/leftMenuSlice';
import { selectTechnicalWorksState } from 'features/app';
import useNotification from 'utils/hooks/use-notification';
import actionsOptions, { type IAction } from 'config/actions-options';
import RESPONSE_STATUS_CODE from 'config/response-status-code';
import ERROR_MESSAGES from 'config/errors/file-folder';

import s from './style.module.scss';

import { IFile } from 'interfaces/file-folder';
import { ILocation } from 'interfaces/utils/history';

type IProps = {
  files: IFile[];
  count: number;
  isGuest: boolean;
  fileViewType: string;
  setFileViewTypeEffect: (type: string) => void;
  isSelectedEntity: boolean;
  selectedEntity: IFile[];
  fileFolderActionHandlerEffect: (
    antity: IFile | IFile[],
    option: IAction
  ) => void;
  selectedFiles: IFile[];
  location: ILocation;
  createFolderEffect: any;
  createDocumentSuccess: any;
  getUserInfoEffect: () => void;
  selectedFilesRemoveEffect: () => void;
};

const HeaderContainer: FC<IProps> = ({
  files,
  count,
  isGuest,
  fileViewType,
  setFileViewTypeEffect,
  isSelectedEntity,
  selectedEntity,
  fileFolderActionHandlerEffect,
  selectedFiles,
  location,
  createFolderEffect,
  createDocumentSuccess,
  getUserInfoEffect,
  selectedFilesRemoveEffect,
  selectedEntityClearEffect,
  modalStates,
  pendingFilesRemove,
  selectedFilesRemove,
  getFilesDispatchEffect,
  uploadDropedFiles,
  getFilesClear,
  isEncryptOpen,
  sidebarVisibility,
  aiSidebarMenu,
  setSidebarVisibility,
  errorCode,
  size,
  isVaultOpen,
  isLinkOpen,
  vaultFiles,
  linkFiles,
  showBurger = true,
}: IProps) => {
  const actionBtnRef = useRef(null);
  const { t: accountT } = useTranslation('account');
  const { t: contextT } = useTranslation('contextMenu');
  const { t: pagesT } = useTranslation('pages');
  const [isOpenRecord, setOpenRecord] = useState(false);
  const [searchOn, setSearchOn] = useState(false);
  const history = useHistory();
  const {
    state: {
      folder: mainFolder,
      isNotificationPage,
      isDocumentPage,
      isAiGeneratorPage,
      isDeletedPage,
    },
  } = useContext(MainContext);
  const { createDocumentOpen, createFolderOpen } = modalStates;
  const entitySettings = useContext(EntitySettings);
  const dispatch = useDispatch();
  const isMobile = window.screen.width < 480;
  const isAiSidebarGenerator =
    isAiGeneratorPage &&
    aiSidebarMenu &&
    fileViewType === 'small-row' &&
    !isMobile;

  const collapsed = useSelector(selectCollapsed);
  const isTechnicalWorks = useSelector(selectTechnicalWorksState);

  (!isDeletedPage && selectedEntity) || (isAiSidebarGenerator && !collapsed)
    ? 345
    : isAiSidebarGenerator && collapsed
    ? 180
    : 0;

  useEffect(() => {
    setSidebarVisibility(false);
  }, [location?.pathname]);

  const { addNotification } = useNotification();

  if (isNotificationPage) return <NotificationsHeader />;

  const toggleSidebar = () => {
    setSidebarVisibility(!sidebarVisibility);
  };

  const downloadEntity = async () => {
    const { download } = actionsOptions;

    if (entitySettings.entity) {
      fileFolderActionHandlerEffect(entitySettings.entity, download);
    } else {
      const result = await fileFolderActionHandlerEffect(
        selectedFiles,
        download
      );
      if (!result) selectedFilesRemoveEffect(selectedFiles);
    }
  };

  const deleteEntity = () => {
    if (isEmpty(selectedFiles)) {
      dispatch(
        handleDeleteModal({ isOpen: true, entity: entitySettings.entity })
      );
    } else {
      deleteMultiFiles(selectedFiles, dispatch, {
        isDocumentPage: location.pathname === '/documents',
        isVaultPage: location.pathname === '/vault',
      })
        .then(() => {
          pendingFilesRemove(selectedFiles);
          selectedFilesRemove(selectedFiles);
          if (
            files.length - selectedFiles.length < 15 &&
            count - files.length > 0
          ) {
            getFilesClear();
            getFilesDispatchEffect(mainFolder?.slug, {
              page: 1,
            });
          }
        })
        .catch((error) => {
          const { code, message } = error.response.data || {};
          if (code === RESPONSE_STATUS_CODE.FORBIDDEN) {
            dispatch(
              setErrors({ message, code: RESPONSE_STATUS_CODE.ACCESS_DENIED })
            );
          } else if (message === ERROR_MESSAGES.TOKENIZED_FILE) {
            addNotification(
              accountT('SafetyNet.cannotDeleteTokenizeObject'),
              'warning',
              false,
              false,
              false,
              5000
            );
          }
        });
    }
  };

  const sortByName = (fileA: IFile, fileB: IFile) => {
    const nameA = fileA?.name?.toUpperCase();
    const nameB = fileB?.name?.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  };

  const createDocumentHandler = (name: string) => {
    dispatch(handleCreateDocumentModal(false));
    const folder = mainFolder?.slug || null;
    saveMemoEffect({ name, folder }).then((data) => {
      const newDocument = { ...data, created_at: moment().unix() };
      if (isDocumentPage) {
        createDocumentSuccess(newDocument);
      } else {
        const newSortedList = [...files, newDocument].sort(sortByName);
        if (newSortedList.length > count) {
          dispatch(getFilesUpdateList(newSortedList));
        } else {
          newSortedList.pop();
          dispatch(getFilesUpdateList(newSortedList));
        }
      }
      if (newDocument.slug) {
        dispatch(
          getFilesActions.getFilesAdd({
            ...data,
            folderId: folderId ?? 'main',
          })
        );
        history.push(
          `/memo/${newDocument.slug}?filename=${newDocument?.name}&filesize=${newDocument?.size}`
        );
      }
    });
  };

  const createFolderHandler = (name: string) => {
    if (name.trim()) {
      const notificationMessage = (
        <>
          <b>{contextT('main.createFolder.folderCreated')}</b>
          <br />
          {contextT('main.createFolder.tapToOpen')}
        </>
      );
      const folderId = mainFolder?.slug || null;
      createFolderEffect(
        name,
        folderId,
        addNotification,
        notificationMessage
      ).then();
      dispatch(handleCreateFolderModal(false));
    }
  };

  const onCloseVideoRecord = () => setOpenRecord(false);

  const onOpenVideoRecord = () => setOpenRecord(true);

  return (
    <header
      className={classNames(
        'main__header',
        isTechnicalWorks && 'main__header_technical'
      )}
    >
      <div className="main__header__actions">
        {showBurger && (
          <div
            className="main__header__actions__sidebar"
            onClick={toggleSidebar}
          >
            <Burger />
          </div>
        )}

        {size !== 'small' && (
          <>
            <ActionButtons
              searchOn={searchOn}
              setSearchOn={setSearchOn}
              actionBtnRef={actionBtnRef}
              selectedFiles={selectedFiles}
              isGuest={isGuest}
              errorCode={errorCode}
              getUserInfoEffect={getUserInfoEffect}
              downloadEntity={downloadEntity}
              deleteEntity={deleteEntity}
              isSelectedEntity={isSelectedEntity}
              isEncryptOpen={isEncryptOpen}
              uploadDropedFiles={uploadDropedFiles}
              onOpenVideoRecord={onOpenVideoRecord}
            />
          </>
        )}
        <ActionSettingButtons
          searchOn={searchOn}
          actionBtnRef={actionBtnRef}
          selectedFiles={selectedFiles}
          selectedEntity={selectedEntity}
          history={history}
        />
      </div>
      {/* {createDocumentOpen && (
        <CreateEntityModal
          closeModal={() => dispatch(handleCreateDocumentModal(false))}
          modalIsOpen={createDocumentOpen}
          onCreate={createDocumentHandler}
          title={pagesT('createMemo')}
          contentLabel={accountT('AIAvatar.createDoc')}
          inputPlaceholder={accountT('AIAvatar.enterTitle')}
          acceptButtonText={accountT('AIAvatar.create')}
          name={accountT('AIAvatar.documentName')}
        />
      )} */}
      {mobile && createFolderOpen && (
        <CreateEntityModal
          closeModal={() => dispatch(handleCreateFolderModal(false))}
          modalIsOpen={createFolderOpen}
          onCreate={createFolderHandler}
          title={contextT('main.createFolder.name')}
          contentLabel={contextT('main.createFolder.name')}
          inputPlaceholder={contextT('main.createFolder.enterName')}
          acceptButtonText={contextT('main.createFolder.create')}
          name={contextT('main.createFolder.folderName')}
        />
      )}
      {isVaultOpen && <VaultModal files={files} vaults={vaultFiles} />}
      {isLinkOpen && <LinkModal files={files} links={linkFiles} />}
      <Modal
        isOpen={sidebarVisibility}
        onRequestClose={toggleSidebar}
        className={classNames(s.Modal, 'Modal')}
        overlayClassName={classNames(s.Overlay, 'Overlay')}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
      >
        <Sidebar className={s.sidebar} />
      </Modal>
      <RecordModal isOpen={isOpenRecord} onClose={onCloseVideoRecord} />
    </header>
  );
};

const mapDispatchToProps = {
  selectedEntityClearEffect,
  setFileViewTypeEffect,
  fileFolderActionHandlerEffect,
  createFolderEffect,
  createDocumentSuccess,
  getUserInfoEffect,
  selectedFilesRemoveEffect,
  pendingFilesRemove,
  selectedFilesRemove,
  getFilesDispatchEffect,
  getFilesClear,
  setSidebarVisibility,
};

const mapStateToProps = (state) => ({
  isGuest: state.home?.userInfo?.user?.isGuest,
  sidebarVisibility: state.leftMenu?.sidebarVisibility,
  aiSidebarMenu: state.leftMenu?.aiSidebarMenu,
  files: state.home?.getFiles?.entities,
  vaultFiles: state.home?.getVaults?.vaults,
  linkFiles: state.home?.getLinks?.links,
  count: state.home?.getFiles?.count,
  isSelectedEntity: state.home?.selectedEntity?.accessAllow,
  fileViewType: state.home?.fileView?.type,
  folders: state.home?.getFolders?.entities,
  selectedFiles: state.home?.selectedFilesFolders?.files,
  modalStates: state.modals,
  errorCode: state.home?.getFiles?.getFilesError?.code,
  selectedEntity: state.home?.selectedEntity?.entity,
  isVaultOpen: selectVaultModal(state),
  isLinkOpen: selectLinkModal(state),
  isEncryptOpen: state.home.encrypt?.openModal,
  uploadDropedFiles: state.home.encrypt?.uploadDropedFiles,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps, null, {
    pure: false,
  }),
  withRouter
)(HeaderContainer);
