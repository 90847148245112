/* eslint-disable no-debugger */
//Refactoring №2
import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  createRef,
  useCallback,
  useMemo,
} from 'react';
import { compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import cn from 'classnames';
import { useDropzone } from 'react-dropzone';
import { useKeyPress, useUpdateEffect } from 'ahooks';

import {
  isEmpty,
  sortWith,
  filter as rFilter,
  pipe,
  reverse,
  prop,
  compose as rCompose,
  toLower,
} from 'ramda';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroller';
import { Portal } from 'react-portal';

import { API_FOLDERS } from 'constants/api-urls';
import * as getFilesActions from '../../../store/home/actions/files/get-files.actions';
import { selectedEntityAdd } from 'store/home/actions/selected-entity.actions';
import {
  showFileClear,
  selectedFilesFoldersClear,
} from 'store/home/actions/selected-files-folders.actions';
import { filesDragClearEffect } from 'store/home/effects/files-drag/files-drag-clear.effect';
import { filesDragAddEffect } from 'store/home/effects/files-drag/files-drag-add.effect';
import { selectedFilesAddEffect } from 'store/home/effects/selected-entity/selected-files-add-effect';
import { selectedFilesRemoveEffect } from 'store/home/effects/selected-entity/selected-files-remove-effect';
import MainContext from 'store/main/context/main-context';
import UserContext from 'store/home/contexts/user-context';
import TokenCostContext from 'store/tokenCost/contexts';
import * as actions from 'store/home/actions/create-folder.actions';
import * as uploadActions from 'store/home/actions/upload-file.actions';
import {
  getFilesDispatchEffect,
  uploadMultiFileEffectRedux,
  getFilesFavoritesEffect,
} from 'store/home/effects';
import fileFolderActionHandlerEffect from 'store/home/effects/entity-actions/entity-action-handler.effect';
import { getTrashEffect } from 'store/trash/effects';
import {
  handleDropedFile,
  handleEncryptModal,
  handleUploadDropedFiles,
} from 'store/home/actions';
import { needEncryptFile } from 'store/home/actions/file/encrypt.action';
import { selectUserFreeActions } from 'store/home/reducers/get-user-info.reducer';
import { canUserEncryptFile } from 'utils/canUserEncryptFile.js';

import actionsOptions from 'config/actions-options';
import { TOKEN_COST } from 'config/token-cost';

import authRequest from 'utils/request/auth-request';
import useNotification from 'utils/hooks/use-notification';
import isExistById from 'utils/files-folders/is-exist-by-id';
import getPropBy from 'utils/array/get-prop-by';
import calculatePositionByElement from 'utils/calculate-position-by-element';
import getMimeType from 'utils/files-folders/get-mime-type';

import GhostTimingContainer from 'containers/main/GhostTimingContainer';
import File from 'containers/shared/file-container';
import UploadFile from 'containers/shared/upload-file-container';
import ContextMenu from 'components/context-menu';
import Folder from 'containers/shared/folder-container';
import FileContainerHeader from 'containers/header/header-container/components/FileContainerHeader';
import {
  selectSorterState,
  selectFilterState,
  selectIsMultisigActivated,
  selectIsMultisigPartisipant,
  selectTokenCountState,
} from 'features/app';
import CustomDragLayer from 'containers/shared/CustomDragLayer';
import EmptyFolder from 'components/shared/Empty/EmptyFolder';
import EmptyForbidden from 'components/shared/Empty/EmptyForbidden';
import FilesSkeleton from 'components/skeletons/files-skeleton';
import AnalyticsModal from 'components/AnalyticsModal';
import FolderSVGImage from 'components/svg/folder-small';
import {
  handleAnalyticsModal,
  handleCreateFolderModal,
  handleCreateDocumentModal,
  handleOpenAiGeneratorModal,
  handleNotEnoughTokenModal,
  handleRecordModal,
  handleVaultModal,
  handleUpload,
} from 'features/modals/modal-slice';

import './entry-list.scss';

const MainFolderFileContainer = ({
  handleDropedFile,
  dropedFiles,
  folderId,
  selectedFiles,
  fileFolderActionHandlerEffect,
  fileViewType,
  match,
  filesDragAddEffect,
  filesDragClearEffect,
  uploadFileEffect,
  uploadingFiles,
  files,
  filesCount,
  pendingFiles,
  filesPending,
  draggedFiles,
  getFilesDispatchEffect,
  getFilesFavoritesEffect,
  isSharedFolder,
  selectedEntity,
  showFile,
  showFileClear,
  selectedEntityAdd,
  emptyView,
  selectedFilesAddEffect,
  selectedFilesRemoveEffect,
  selectedFilesFoldersClear,
  openModal,
  trash,
  favorite,
  optionHandler,
  onUpload,
  history,
  modals,
  errorCode,
  getTrashEffect,
  needEncryption,
  currentPage,
  sorter,
  filter,
  userTokens,
  isMultisigActivated,
  isMultisigPartisipant,
  uploadDropedFiles,
  fileTypeForRequest,
  wasLoaded,
  gatewayDataForThumbnails,
}) => {
  const { t } = useTranslation('owner');
  const [isDragActive, setIsDragActive] = useState(false);
  const [showContext, setShowContext] = useState(false);
  const [visible, setVisible] = useState(false);
  const [contextPosition, setContextPosition] = useState({
    top: '10px',
    left: '50%',
  });
  const [droppedFiles, setDroppedFiles] = useState([]);
  const [selectAllCheckbox, setSelectAllCheckbox] = useState(false);
  const [file, setFile] = useState(null);
  const [nameSortDirection, setNameSortDirection] = useState(false);
  const [func, setFunc] = useState(null);
  const [option, setOption] = useState(null);
  const [isUploadInProgress, setIsUploadInProgress] = useState(false);
  const {
    state: { folder: mainFolder, workspace },
    dropUploaderRef,
  } = useContext(MainContext);
  const fileRef = useRef(null);
  const { user: me } = useContext(UserContext);
  const { tokenCost } = useContext(TokenCostContext);
  const { addNotification } = useNotification();
  const dispatch = useDispatch();
  const storageType = workspace.storage;
  const freeActions = useSelector(selectUserFreeActions);
  const freeEncryptionsCount = useMemo(() => {
    const freeEncryptionsAction =
      freeActions &&
      freeActions.find((el) => el.action === 'upload_encrypted_files');

    return freeEncryptionsAction?.count ?? 0;
  }, [freeActions]);
  const selectedSlugs = useMemo(
    () => selectedFiles.map((item) => item.slug),
    [selectedFiles]
  );
  const multisigDisabled = isMultisigActivated && !isMultisigPartisipant;
  const isTokenizedFolder = mainFolder?.entry_groups?.some(
    (item) => item.is_tokenized
  );
  const isBetaEnviroment = useMemo(
    () => process.env.REACT_APP_ENVIROMENT === 'BETA',
    []
  );
  const [isAllowed, setIsAllowed] = useState(false);
  const [processedFiles, setProcessedFiles] = useState([]);
  const queue = window.queue || [];
  const lastQueue = queue ? queue[queue?.length - 1] : null;

  const contextOptions = useMemo(() => {
    if (isBetaEnviroment)
      return [
        actionsOptions.createFolder,
        actionsOptions.upload,
        actionsOptions.addLink,
      ];
    return [
      actionsOptions.createFolder,
      actionsOptions.upload,
      actionsOptions.addPage,
      actionsOptions.addPassword,
      actionsOptions.addLink,
    ];
  }, []);
  const filesRef = useRef([]);

  useEffect(() => {
    dispatch(getFilesActions.updateCurrentPage(1));

    return () => {
      dispatch(getFilesActions.updateCurrentPage(1));
    };
  }, []);

  useEffect(() => {
    if (
      workspace?.show_encrypt_modal &&
      !openModal &&
      !isEmpty(dropedFiles) &&
      wasLoaded
    ) {
      setDroppedFiles(dropedFiles);
      dispatch(handleUploadDropedFiles(true));
      handleDropedFile([]);
    }

    return () => {
      setDroppedFiles([]);
    };
  }, [dropedFiles, openModal, wasLoaded]);

  useKeyPress(['ctrl.a', 'meta.a'], () => {
    if (selectedEntity.entity) return null;

    const noSecureFils = files.filter((file) => isEmpty(file.securities));
    if (!selectAllCheckbox) {
      selectedFilesAddEffect({ entities: files });
      setSelectAllCheckbox(true);
    } else {
      selectedFilesRemoveEffect(noSecureFils);
      setSelectAllCheckbox(false);
    }
  });

  useKeyPress(['enter'], () => {
    if (
      document.activeElement?.nodeName !== 'INPUT' &&
      selectedEntity.entity &&
      !Object.values(modals).some((item) => item)
    ) {
      const entity = selectedEntity.entity;
      if (entity.type === 2) {
        history.push(`folder/${entity.slug}`);
      } else {
        fileFolderActionHandlerEffect(entity, actionsOptions.viewFile);
      }
    }
  });

  useUpdateEffect(() => {
    const noSecureFils = files.filter((file) => isEmpty(file.securities));
    if (selectAllCheckbox && selectedFiles.length === 0) {
      setSelectAllCheckbox(false);
      selectedFilesRemoveEffect(noSecureFils);
    }
    if (selectAllCheckbox && selectedFiles.length !== 0) {
      selectedFilesRemoveEffect(files);
      selectedFilesAddEffect({ entities: noSecureFils });
    }
    if (files.length === 0) {
      setSelectAllCheckbox(false);
    }
  }, [files, selectedFiles.length === 0, selectAllCheckbox]);

  const onSelectAllFileHandler = useCallback(() => {
    const noSecureFils = files.filter((file) => isEmpty(file.securities));
    if (!selectAllCheckbox) {
      setSelectAllCheckbox(true);
      selectedFilesAddEffect({ entities: noSecureFils });
      return;
    }
    setSelectAllCheckbox(false);
    selectedFilesRemoveEffect(noSecureFils);
  }, [files, selectAllCheckbox]);

  useEffect(async () => {
    let filesArray = [];
    if (!openModal && !!droppedFiles.length) {
      const folderId = match.params.folderId;

      const getResult = async (files, id, folderData) => {
        for (let i = 0; i < files?.length; i++) {
          let folderD;
          let foldersResponse;
          let folder;
          if (files[i]?.type === 'folder') {
            dispatch(actions.createFolder());
            if (folderId === id) {
              const CancelToken = axios.CancelToken;
              const source = CancelToken.source();
              const uploadID = `${files[i].name}_${files[i].size}_${id}_${files[i].timestamp}`;
              folder = {
                folderId: undefined,
                uploadId: uploadID,
                source: source,
                dataType: 'folder',
                size: getSize(files[i]),
                name: files[i].name,
                needEncryption:
                  files[i]?.needEncryption !== undefined
                    ? files[i]?.needEncryption
                    : needEncryption,
                slug: uploadID,
                id: uploadID,
              };
              dispatch(uploadActions.uploadFile(folder));
              dispatch(
                getFilesActions.getFilesAddPreload({
                  ...folder,
                  preload: true,
                  type: 2,
                })
              );
            }

            const dataToPost = {
              name: files[i].name,
              parent: id || null,
            };
            if (!isUploadInProgress) {
              foldersResponse = authRequest.post(
                `${API_FOLDERS}/folder`,
                dataToPost
              );

              await foldersResponse
                .then(async (result) => {
                  if (folder) {
                    folder.folderId = await result?.data?.data?.slug;
                    dispatch(uploadActions.uploadFile(folder));
                    if (folderId === id) {
                      dispatch(
                        getFilesActions.getFilesUpdatePreload({
                          entity: {
                            ...result.data?.data,
                            folderId: id ?? 'main',
                            preload: true,
                            uploadId: folder.uploadId,
                          },
                          prevSlug: folder.slug,
                        })
                      );
                    }
                  }
                  if (!folderData) {
                    folderD = {
                      slug: files[i].name,
                      uploadId: folder?.uploadId,
                    };
                  }
                  const startTime = Date.now();
                  if (files[i]?.files) {
                    const allowedFiles = files[i]?.files;
                    const data = folderD || folderData;
                    allowedFiles.map((file) => {
                      file.folderData = data;
                      file.folderId = result.data?.data?.slug;
                      file.needEncryption = folder?.needEncryption;
                      file.folderSize = folder?.size;
                      file.startedAt = startTime;
                      return file;
                    });
                    filesArray = [...filesArray, ...allowedFiles];
                  }
                  if (files[i]?.folders.length) {
                    await getResult(
                      files[i]?.folders,
                      result.data?.data?.slug,
                      folderD || folderData,
                      files[i].timestamp
                    );
                  }
                })
                .catch((e) => {
                  throw e;
                });
            }
          }
          if (files[i]?.type !== 'folder') {
            filesArray.push(files[i]);
          }
        }
      };

      await getResult(droppedFiles, folderId, null);

      filesArray.map((file) => {
        if (!file?.uploadId) {
          const currentUrl = window.location.href.split('/');
          const currentFolder = currentUrl[currentUrl.length - 1];
          const CancelToken = axios.CancelToken;
          const source = CancelToken.source();
          const timestamp = Date.now();
          file.folderId = file?.folderId || folderId || null;
          file.uploadId =
            file?.folderData?.uploadId ||
            `${file.name}_${file.size}_${file.folderId}_${timestamp}`;
          file.source = source;
          file.mime = getMimeType(file);
          file.fileName = file.name;
          if (file?.needEncryption === undefined) {
            file.needEncryption = needEncryption;
          }
          if (
            file.folderId === currentFolder ||
            (file.folderId === null && currentFolder === 'main') ||
            (file.folderId === null && currentFolder === 'pictures')
          ) {
            dispatch(uploadActions.uploadFile(file));
            dispatch(
              getFilesActions.getFilesAdd(
                {
                  file: file,
                  preload: true,
                  id: file.uploadId,
                  slug: file.uploadId,
                  name: file.name,
                  size: file.size,
                  mime: file.type,
                  type: file?.type === 'folder' ? 2 : 1,
                  uploadId: file.uploadId,
                  folderId: file.folderId || 'main',
                },
                false
              )
            );
          }
        }
      });

      const processed = [];

      for (let i = 0; i < filesArray.length; i++) {
        const isExist = processedFiles.find(
          (file) => file.uploadId === filesArray[i].uploadId
        );
        if (!isExist) {
          processed.push(filesArray[i]);
          setDroppedFiles([]);
        }
      }
      setProcessedFiles(processed);
    }

    if (
      !openModal &&
      !!droppedFiles.length &&
      !isUploadInProgress &&
      isAllowed
    ) {
      const queue = window?.queue || [];
      const queueItem = { id: generateRandomID(), status: 'uploading' };

      window.queue = [...queue, queueItem];

      uploaderHandler(filesArray, queueItem);
      setProcessedFiles([]);
      setDroppedFiles([]);
      dispatch(needEncryptFile(undefined));
      setIsAllowed(false);
    }
  }, [openModal, droppedFiles, isUploadInProgress, isAllowed]);

  const newQueueItem = { id: generateRandomID(), status: 'uploading' };

  useEffect(async () => {
    let timeoutId;

    if (
      !queue?.some((item) => item.id === newQueueItem.id) &&
      processedFiles.length &&
      !isAllowed
    ) {
      window.queue = [...queue, newQueueItem];
    }
    if (
      queue?.length &&
      window?.queue?.some((item) => item.status === 'uploading')
    ) {
      timeoutId = setInterval(async () => {
        const last = queue.find((item) => item.id === lastQueue.id);
        if (last.status === 'done') {
          if (processedFiles.length && !isAllowed) {
            uploaderHandler(processedFiles, newQueueItem);
            setProcessedFiles([]);
            setDroppedFiles([]);
            dispatch(needEncryptFile(undefined));
            window.queue = window.queue.filter((item) => item.id !== last.id);
            if (
              !queue?.length &&
              !window?.queue?.some((item) => item.status === 'uploading')
            ) {
              setIsAllowed(true);
            }
          }
          clearInterval(timeoutId);
        }
      }, 5000);
    } else {
      setIsAllowed(true);
    }
    return () => {
      clearInterval(timeoutId);
    };
  }, [processedFiles]);

  const onNameClickSort = useCallback(() => {
    setNameSortDirection(!nameSortDirection);
  }, [nameSortDirection]);

  const getSize = useCallback((item) => {
    let folderSize = 0;
    if (item.type === 'folder') {
      const { folders, files } = item;
      for (let i = 0; i < folders.length; i++) {
        folderSize += getSize(item.folders[i]);
      }
      for (let i = 0; i < files.length; i++) {
        folderSize += files[i].size;
      }
    }
    return folderSize;
  }, []);

  const changeQueueStatus = useCallback((queueItem) => {
    const prevQueue = window.queue;
    const nextQueue = prevQueue.map((item) => {
      if (item.id === queueItem.id) {
        item.status = 'done';
      }
      return item;
    });
    window.queue = nextQueue;
  }, []);

  const uploaderHandler = async (files, queueItem) => {
    setIsUploadInProgress(true);
    if (draggedFiles.length > 0) return null;

    const allowedFiles = files;
    if (onUpload) onUpload();
    if (allowedFiles.length !== 0) {
      uploadFileEffect({
        files: allowedFiles,
        folderId: folderId,
        storageType,
        addNotification,
        userTokens,
        endUpload: () => {
          setIsUploadInProgress(false);
          changeQueueStatus(queueItem);
        },
        user: me,
        freeEncryptionsCount,
      }).catch(() => {
        addNotification(t('common.somethingWentWrong'), 'alert');
      });
    }
  };

  const { getInputProps, open } = useDropzone({
    onDrop: (data) => {
      data.map((file) => {
        const timestamp = Date.now();
        file.needEncryption = needEncryption;
        file.timestamp = timestamp;
        return file;
      });

      setDroppedFiles([...droppedFiles, ...data]);
    },
  });
  const useFolderPermissions = folderId && isSharedFolder;

  useEffect(() => {
    if (dropUploaderRef) {
      dropUploaderRef.current = { open: open };
    }

    return () => {
      if (dropUploaderRef) {
        dropUploaderRef.current = null;
      }
    };
  }, [open, dropUploaderRef]);

  const loadMore = useCallback(() => {
    if (currentPage * 15 < filesCount) {
      const nextPage = currentPage + 1;
      if (trash) {
        getTrashEffect(
          {
            page: nextPage,
            order_by: sorter.option,
            order: sorter.dir,
          },
          false
        ).then(() => {
          dispatch(getFilesActions.updateCurrentPage(nextPage));
        });
      } else if (favorite) {
        getFilesFavoritesEffect({
          page: nextPage,
          order_by: sorter.option,
          order: sorter.dir,
          extension: filter.fileType,
          isTokenized: filter.isTokenized,
          color: filter.color,
        }).then(() => {
          dispatch(getFilesActions.updateCurrentPage(nextPage));
        });
      } else {
        getFilesDispatchEffect(folderId, {
          page: nextPage,
          order_by: sorter.option,
          order: sorter.dir,
          extension: fileTypeForRequest || filter.fileType,
          isTokenized: filter.isTokenized,
          color: filter.color,
        }).then(() => {
          dispatch(getFilesActions.updateCurrentPage(nextPage));
        });
      }
    }
  }, [currentPage, filesCount, folderId, sorter, filter]);

  const hasMore = useMemo(() => {
    return (
      files.length < filesCount &&
      !filesPending &&
      files.length <= currentPage * 15
    );
  }, [files, filesCount, filesPending, currentPage]);

  const onCheckChange = useCallback(() => {
    selectAllCheckbox && setSelectAllCheckbox(false);
  }, [selectAllCheckbox]);

  const onDropFileHandler = useCallback(
    (folder, file, isDragNotSelectedFile) => {
      const entities =
        !selectedFiles?.length || isDragNotSelectedFile ? file : selectedFiles;
      const additionalData = {
        folderId: folder?.slug,
        securityCheckEntities: folder,
      };

      fileFolderActionHandlerEffect(
        entities,
        actionsOptions.move,
        additionalData
      );
      selectedFiles?.length && selectedFilesFoldersClear();
    },
    [selectedFiles]
  );

  const openContainer = useCallback((file, func, option) => {
    setVisible(true);
    setFile(file);
    setFunc(func);
    setOption(option);
  }, []);

  const onDragBeginHandler = useCallback(
    (file, isDragNotSelectedFile) => {
      const entities =
        !selectedFiles?.length || isDragNotSelectedFile ? file : selectedFiles;
      filesDragAddEffect(entities);
    },
    [selectedFiles]
  );

  const onDragEndHandler = useCallback(() => {
    filesDragClearEffect();
  }, []);

  const closeAnalytics = useCallback(() => {
    dispatch(handleAnalyticsModal({ status: false, slug: null }));
  }, []);

  const renderFilePending = useCallback(() => {
    return filesPending && <FilesSkeleton viewType={fileViewType} count={15} />;
  }, [filesPending, fileViewType]);

  const sortNamesAsc = useMemo(
    () =>
      files ? sortWith([prop('type'), rCompose(toLower, prop('name'))]) : [],
    []
  );

  const folderItems = rFilter(
    (item) =>
      item.type === 2 &&
      ((trash && item.folderId === 'trash') ||
        (!trash && item.folderId === 'main') ||
        item.folderId === folderId),
    files
  );
  const filesItems = rFilter(
    (item) =>
      item.type === 1 &&
      ((trash && item.folderId === 'trash') ||
        (!trash && item.folderId === 'main') ||
        item.folderId === folderId ||
        item?.file?.folderId === folderId),
    files
  );

  const sortNamesDesc = pipe(sortNamesAsc, reverse);

  const sortArr = useMemo(
    () => (nameSortDirection ? sortNamesDesc : sortNamesAsc),
    [nameSortDirection, sortNamesDesc]
  );
  const slugs = [];
  const sortedFiles = useMemo(
    () =>
      [...sortArr(folderItems), ...sortArr(filesItems)].filter((item) => {
        if (!slugs[item.slug]) {
          slugs[item.slug] = 1;
          return true;
        }
        return false;
      }),
    [folderItems, filesItems, slugs]
  );

  useEffect(() => {
    if (showFile) {
      const index = sortedFiles.findIndex((el) => {
        return el.name === showFile.name;
      });
      if (index >= 0) {
        filesRef.current[index].current?.scrollIntoView({
          block: 'center',
          inline: 'nearest',
        });
        selectedEntityAdd({ entity: sortedFiles[index] });
        setTimeout(() => showFileClear(), 2000);
      }
    }
  }, [showFile]);

  if (filesRef.current.length !== sortedFiles.length) {
    filesRef.current = Array(sortedFiles.length)
      .fill()
      .map((_, i) => filesRef.current[i] || createRef());
  }

  const renderFilesItems = useCallback(() => {
    const {
      remove,
      goToSecuritySettings,
      timing,
      ghostMode,
      download,
      edit,
      manageShare,
      accessPreferences,
    } = actionsOptions;

    return sortedFiles?.map((file, index) => {
      const extendedFile =
        useFolderPermissions && file.folder
          ? {
              ...file,
              shares: [...file.folder?.shares],
            }
          : { ...file };
      const hasPending = isExistById(extendedFile, pendingFiles);
      const isSelected = !!getPropBy(selectedFiles, 'slug', extendedFile?.slug);
      const isDragged = !!getPropBy(draggedFiles, 'slug', extendedFile?.slug);
      const canSelect = isEmpty(file.securities);

      if (file?.preload) {
        return (
          <UploadFile
            ref={fileRef}
            file={file}
            key={file.id}
            isDragged={isDragged}
            viewType={fileViewType}
            showSharing={useFolderPermissions}
            selectedEntity={selectedEntity}
            fileFolderActionHandlerEffect={fileFolderActionHandlerEffect}
            selectedFilesAddEffect={selectedFilesAddEffect}
            selectedFilesRemoveEffect={selectedFilesRemoveEffect}
            trash={trash}
            optionHandler={optionHandler}
            uploadingFiles={uploadingFiles}
          />
        );
      }

      if (file?.type === 1) {
        return (
          <File
            selectedSlugs={selectedSlugs}
            ref={fileRef}
            showFile={showFile?.name === file.name}
            file={extendedFile}
            key={file.id}
            forwardedRef={filesRef.current[index]}
            viewType={fileViewType}
            options={[
              edit,
              ghostMode,
              goToSecuritySettings,
              manageShare,
              accessPreferences,
              timing,
              remove,
              download,
            ]}
            canDrag={(!multisigDisabled && !trash) || !isTokenizedFolder}
            isEdit={modals.editOpen === file.slug}
            disabled={hasPending}
            isSelected={isSelected}
            isDragged={isDragged}
            canSelect={canSelect}
            onDragBegin={onDragBeginHandler}
            onDragEnd={onDragEndHandler}
            onOpenGhostTiming={openContainer}
            onCheckChange={onCheckChange}
            showSharing={useFolderPermissions}
            selectedEntity={selectedEntity}
            fileFolderActionHandlerEffect={fileFolderActionHandlerEffect}
            selectedFilesAddEffect={selectedFilesAddEffect}
            selectedFilesRemoveEffect={selectedFilesRemoveEffect}
            trash={trash}
            optionHandler={optionHandler}
            gatewayData={gatewayDataForThumbnails[file.slug]}
          />
        );
      }
      return (
        <Folder
          selectedSlugs={selectedSlugs}
          folder={extendedFile}
          key={file.id}
          viewType={fileViewType}
          onEditName={() => {}}
          forwardedRef={filesRef.current[index]}
          isDragged={isDragged}
          onDropHandler={onDropFileHandler}
          onDragBegin={onDragBeginHandler}
          onDragEnd={onDragEndHandler}
          onCheckChange={onCheckChange}
          canDrag={!multisigDisabled}
          isEdit={modals.editOpen === file.slug}
          hasPending={hasPending}
          isSelected={isSelected}
          canSelect={canSelect}
          trash={trash}
          selectedEntity={selectedEntity}
          fileFolderActionHandlerEffect={fileFolderActionHandlerEffect}
          selectedFoldersAddEffect={selectedFilesAddEffect}
          selectedFoldersRemoveEffect={selectedFilesRemoveEffect}
          optionHandler={optionHandler}
        />
      );
    });
  }, [
    sortedFiles,
    useFolderPermissions,
    pendingFiles,
    selectedSlugs,
    draggedFiles,
    selectedFiles,
    showFile,
    filesRef,
    fileViewType,
    multisigDisabled,
    trash,
    modals,
    selectedEntity,
  ]);

  const closeContainer = useCallback(() => {
    setVisible(false);
  }, []);

  const ghostTimingContainer = useCallback(() => {
    if (visible) {
      return (
        <GhostTimingContainer
          onClose={closeContainer}
          file={file}
          func={func}
          option={option}
        />
      );
    } else {
      return false;
    }
  }, []);

  const renderUploadMessage = useMemo(
    () =>
      isDragActive && (
        <div className="entry-upload">
          <div className="entry-upload-title">
            {t('vault.selectedFilesUpload')}
          </div>
          <div className="entry-upload-folder">
            <div className="entry-upload-folder-icon">
              <FolderSVGImage color="#FFFFFF" fillAll />
            </div>
            <div className="entry-upload-folder-name">
              {mainFolder ? mainFolder.name : 'My Drive'}
            </div>
          </div>
        </div>
      ),
    []
  );

  /**
   * files end
   * */

  const getFilesDataTransferItems = (dataTransferItems) => {
    function getFolderData(item, path = '', folders, allFiles) {
      return new Promise((resolve) => {
        if (item.isFile) {
          const fullPath = item?.fullPath.split('/');
          item.file((file) => {
            let template = new File([file], file.name, { type: file.type });
            const data = { ...template.props }[0];
            data.parent = fullPath[fullPath.length - 2];
            if (file.name !== '.DS_Store') {
              allFiles.push(data);
            }
            resolve(file);
          });
        } else if (item.isDirectory) {
          let dirReader = item.createReader();
          let readEntries = () => {
            return readEntriesPromise(dirReader).then((entries) => {
              if (entries.length > 0) {
                let subfolder = [];
                let allFiles = [];
                const existingFolder = folders.find(
                  (folder) => folder.name === item.name
                );
                if (!existingFolder) {
                  folders.push({
                    type: 'folder',
                    name: item.name,
                    files: allFiles,
                    folders: subfolder,
                  });
                } else {
                  const pos = folders.map((i) => i.name).indexOf(item.name);
                  if (pos !== -1) {
                    allFiles.unshift(...folders[pos].files);
                    subfolder.unshift(...folders[pos].folders);
                    folders[pos] = {
                      type: 'folder',
                      name: item.name,
                      files: allFiles,
                      folders: subfolder,
                    };
                  }
                }

                let entriesPromises = entries.map((entry) =>
                  getFolderData(
                    entry,
                    path || '' + item.name + '/',
                    subfolder,
                    allFiles
                  )
                );

                return Promise.all(entriesPromises).then(() => readEntries());
              } else {
                resolve();
              }
            });
          };

          readEntries();
        }
      });
    }

    function getFileData(item, allFiles) {
      return new Promise((resolve) => {
        item.file((file) => {
          if (file.name !== '.DS_Store') {
            let template = new File([file], file.name, { type: file.type });
            const data = { ...template.props }[0];
            data.parent = null;
            allFiles.push(data);
          }
          resolve(file);
        });
      });
    }

    let files = [];

    return new Promise((resolve) => {
      let entriesPromises = [];
      for (let it of dataTransferItems) {
        if (it.webkitGetAsEntry()?.isDirectory) {
          entriesPromises.push(
            getFolderData(it.webkitGetAsEntry(), null, files)
          );
        }
        if (it.webkitGetAsEntry()?.isFile) {
          entriesPromises.push(getFileData(it.webkitGetAsEntry(), files));
        }
      }

      Promise.all(entriesPromises).then(() => {
        resolve(files);
      });
    });
  };

  const readEntriesPromise = (dirReader) => {
    return new Promise((resolve, reject) => {
      dirReader.readEntries((entries) => {
        resolve(entries);
      }, reject);
    });
  };

  const onDragStart = useCallback(
    (e) => {
      e.preventDefault();
      if (!multisigDisabled && !isTokenizedFolder) setIsDragActive(true);
    },
    [multisigDisabled, isTokenizedFolder]
  );

  const onDragEnd = useCallback(() => {
    if (!multisigDisabled && !isTokenizedFolder) setIsDragActive(false);
  }, [multisigDisabled, isTokenizedFolder]);

  const onDrop = useCallback(
    (e) => {
      e.preventDefault();
      if (multisigDisabled || isTokenizedFolder) return;

      const encryptFiles = workspace?.encrypt_files_on_upload;
      const modalVisibility = workspace?.show_encrypt_modal;

      if (encryptFiles) {
        dispatch(needEncryptFile(true));
      }

      let items = e.dataTransfer.items;
      getFilesDataTransferItems(items).then((files) => {
        if (files.length) dispatch(handleEncryptModal(modalVisibility));
        files.map((file) => {
          const timestamp = Date.now();
          file.timestamp = timestamp;
          return file;
        });
        setDroppedFiles([...droppedFiles, ...files]);
        handleDropedFile([...droppedFiles, ...files]);
        dispatch(handleUploadDropedFiles(true));
      });
      setIsDragActive(false);
    },
    [multisigDisabled, workspace, droppedFiles, isTokenizedFolder]
  );

  const onRightFileClickHandler = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      const { clientY, clientX } = e;

      if (
        clientY === 0 ||
        clientX === 0 ||
        multisigDisabled ||
        isTokenizedFolder
      )
        return;
      const position = calculatePositionByElement(e, contextOptions.length);

      setShowContext(true);
      setContextPosition(position);
    },
    [multisigDisabled, contextOptions, isTokenizedFolder]
  );

  const onClickOutsideContext = useCallback(() => {
    setShowContext(false);
  }, []);

  const openAiGenerator = useCallback(() => {
    const aiCost = tokenCost.reduce((_, item) => {
      if (TOKEN_COST.AIImages === item.id) {
        return item.tokens;
      }
      return _;
    }, 0);
    if (userTokens >= aiCost) {
      dispatch(handleOpenAiGeneratorModal(true));
    } else {
      dispatch(handleNotEnoughTokenModal(true));
    }
  }, [me, tokenCost]);

  const openAiPlayground = useCallback(() => {
    const aiCost = tokenCost.reduce((_, item) => {
      if (TOKEN_COST.AIImages === item.id) {
        return item.tokens;
      }
      return _;
    }, 0);
    if (userTokens >= aiCost) {
      dispatch(handleOpenAiGeneratorModal(true));
    } else {
      dispatch(handleNotEnoughTokenModal(true));
    }
  }, [me, tokenCost]);

  const onAddFile = async () => {
    const encryptFiles = workspace?.encrypt_files_on_upload;
    const modalVisibility = workspace?.show_encrypt_modal;

    dispatch(handleEncryptModal(modalVisibility));

    if (!modalVisibility && !encryptFiles) {
      dispatch(needEncryptFile(false));
      onAddFileEncrypt();
    }
    if (!modalVisibility && encryptFiles) {
      const canEncrypt = await canUserEncryptFile(me?.user_public_addresses);

      if (!canEncrypt?.error) {
        dispatch(needEncryptFile(true));
        onAddFileEncrypt();
      } else {
        addNotification(canEncrypt?.message, 'alert');
      }
    }
  };

  const handleCloseEncrypt = () => {
    dispatch(handleEncryptModal(false));
    dispatch(handleUploadDropedFiles(false));
  };

  const onAddFileEncrypt = () => {
    if (uploadDropedFiles) {
      handleCloseEncrypt();
    } else if (dropUploaderRef?.current && !errorCode) {
      dropUploaderRef.current.open();
    }
  };

  const onActionHandler = useCallback((option) => {
    switch (option.type) {
      case actionsOptions.createFolder.type:
        dispatch(handleCreateFolderModal(true));
        break;
      case actionsOptions.addPage.type:
        dispatch(handleCreateDocumentModal(true));
        break;
      case actionsOptions.upload.type:
        dispatch(handleUpload(true));
        break;
      case actionsOptions.addRecord.type:
        dispatch(handleRecordModal(true));
        break;
      case actionsOptions.addPassword.type:
        dispatch(handleVaultModal({ isOpen: true }));
        break;
      case actionsOptions.addLink.type:
        history.push('/links');
        break;
      case actionsOptions.aiGenerator.type:
        openAiGenerator();
        break;
      case actionsOptions.aiPlayground.type:
        openAiPlayground();
        break;

      default:
        return null;
    }
  }, []);

  const renderContext = useMemo(
    () =>
      showContext ? (
        <Portal>
          <ContextMenu
            contextPosition={contextPosition}
            actionHandler={onActionHandler}
            onClickOutside={onClickOutsideContext}
            options={contextOptions}
            permission={false}
          />
        </Portal>
      ) : null,
    []
  );

  return (
    <div
      className={cn(
        'entry-list',
        isDragActive && 'entry-list-active',
        files?.length === 0 && !filesPending && 'entry-list-empty'
      )}
      onDragOver={onDragStart}
      onDrop={onDrop}
      onDragLeave={onDragEnd}
      onContextMenu={onRightFileClickHandler}
    >
      <input {...getInputProps()} />
      <CustomDragLayer selectedSlugs={selectedSlugs} />
      {ghostTimingContainer()}
      {!isEmpty(files) && (
        <FileContainerHeader
          viewType={fileViewType}
          nameSortDirection={nameSortDirection}
          checked={selectAllCheckbox}
          onSelectAllFileHandler={onSelectAllFileHandler}
          onNameClickSort={onNameClickSort}
        />
      )}
      {sortedFiles?.length > 0 ? (
        <InfiniteScroll
          className={cn({
            'files-container__content': fileViewType !== 'square',
            'files-container__content-square': fileViewType === 'square',
            isSelectedOnLaptop: selectedEntity.entity,
          })}
          initialLoad={true}
          pageStart={2}
          loadMore={loadMore}
          hasMore={hasMore}
          threshold={250}
          useWindow={false}
          getScrollParent={() =>
            document.getElementsByClassName('entry-list ')[0]
          }
        >
          {renderFilesItems()}
        </InfiniteScroll>
      ) : filesPending || uploadingFiles?.length ? null : emptyView ? (
        emptyView
      ) : errorCode == 403 ? (
        <div className="entry-list-empty">
          <EmptyForbidden />
        </div>
      ) : (
        <EmptyFolder btnText={undefined} handleDropDown={onAddFile} />
      )}
      {renderFilePending()}
      {renderUploadMessage}
      <AnalyticsModal
        closeModal={closeAnalytics}
        isOpen={modals.analyticsOpen}
        slug={modals.additionalData}
      />
      {renderContext}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isMultisigActivated: selectIsMultisigActivated(state),
  isMultisigPartisipant: selectIsMultisigPartisipant(state),
  fileViewType: state.home?.fileView?.type,
  selectedFiles: state.home?.selectedFilesFolders?.files,
  showFile: state.home?.selectedFilesFolders?.show,
  uploadingFiles: state.home?.uploadFiles?.entities,
  files: state.home?.getFiles?.entities,
  filesPending: state.home?.getFiles?.status,
  filesCount: state.home?.getFiles?.count,
  filesReload: state.home?.getFiles?.reload,
  pendingFiles: state.home?.pendingFilesFolders?.files,
  draggedFiles: state.home?.filesDrag?.entities,
  selectedEntity: state.home?.selectedEntity,
  modals: state.modals,
  errorCode: state.home?.getFiles?.getFilesError?.code,
  needEncryption: state?.home?.encrypt?.needEncryption,
  dropedFiles: state?.home?.encrypt?.dropedFiles,
  wasLoaded: state.home?.getFiles?.wasLoaded,
  openModal: state?.home?.encrypt?.openModal,
  currentPage: state?.home?.getFiles?.currentPage,
  sorter: selectSorterState(state),
  filter: selectFilterState(state),
  userTokens: selectTokenCountState(state),
  uploadDropedFiles: state.home.encrypt?.uploadDropedFiles,
  gatewayDataForThumbnails: state.home?.getFiles?.gatewayDataForThumbnails,
});

const mapDispatchToProps = {
  getFilesFavoritesEffect,
  fileFolderActionHandlerEffect,
  uploadFileEffect: uploadMultiFileEffectRedux,
  selectedFilesFoldersClear,
  getFilesDispatchEffect,
  filesDragClearEffect,
  filesDragAddEffect,
  selectedFilesAddEffect,
  selectedFilesRemoveEffect,
  handleDropedFile,
  showFileClear,
  selectedEntityAdd,
  getTrashEffect,
};

export default React.memo(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
  )(MainFolderFileContainer)
);

export const generateRandomID = () => {
  const randomNumber = Math.random().toString(36).substring(2);
  const timestamp = new Date().getTime().toString(36);
  return `${randomNumber}${timestamp}`;
};
