// Utils
import axios from 'axios';

import authRequest from 'utils/request/auth-request';

// Store
import {
  fileRemoveSuccess,
  fileRemoveFailure,
} from '../../actions/file/remove-file-from-trash.actions';
import { getFilesRemove } from '../../actions/files/get-files.actions';
import { selectedFilesRemove } from '../../actions/selected-files-folders.actions';
import {
  FileCountActionType,
  workspaceUpdateFileCountByType,
} from 'features/workspaceSettings/workspaceSettingsSlice';

export default async (dispatch, files) => {
  const slugs = files.map((file) => {
    return { slug: file.slug };
  });

  const {
    data: {
      jwt_ott,
      user_tokens: { token: oneTimeToken },
      gateway,
    },
  } = await getDeleteOTT(slugs);

  const url = `${gateway.url}/trash/delete`;

  await axios
    .create({
      headers: {
        'one-time-token': oneTimeToken,
        'Content-Type': 'application/json',
      },
    })
    .delete(url, { data: { token: jwt_ott } })
    .then(() => {
      dispatch(fileRemoveSuccess());
      dispatch(selectedFilesRemove(files));
      dispatch(getFilesRemove(files));
      dispatch(
        workspaceUpdateFileCountByType({
          actionType: FileCountActionType.DELETE_PERMANENTLY,
          count: files.length,
        })
      );
    })
    .catch(() => {
      dispatch(fileRemoveFailure());
    });
};

export const getDeleteOTT = (body) => {
  const url = `${process.env.REACT_APP_API_PATH}/delete/generate/token`;
  return authRequest.post(url, body);
};
