const categories: { [key: string]: string[] } = {
  images: [
    'jpg',
    'jpeg',
    'jfif',
    'pjpeg',
    'pjp',
    'apng',
    'avif',
    'png',
    'svg',
    'webp',
    'gif',
    'tif',
    'tiff',
    'nef',
    'bmp',
    'ico',
    'dds',
    'psd',
    'heic',
  ],
  audios: [
    'ape',
    'amr',
    'aiff',
    'aac',
    'ac3',
    'm4a',
    'aif',
    'cda',
    'mid',
    'midi',
    'mp3',
    'mpa',
    'ogg',
    'wavm',
    'wav',
    'wma',
    'wpl',
    'flac',
  ],
  videos: [
    'mp4',
    'mpeg',
    'mpg',
    'avi',
    'mov',
    '3g2',
    '3gp',
    'm4v',
    'mkv',
    'wmv',
    'webm',
    'flv',
    'vob',
    'ogv',
    'rm',
  ],
  docs: [
    'doc',
    'docx',
    'gd',
    'txt',
    'rtf',
    'odt',
    'tex',
    'wpd',
    'pdf',
    'json',
    'xls',
    'xlsx',
    'xml',
    'csv',
    'epub',
  ],
  memos: ['memo'],
  links: ['link'],
  notes: ['notes'],
};

type Category = keyof typeof categories;

export const getCategory = (extension: string): Category | null => {
  for (const category in categories) {
    if (categories[category as Category].includes(extension)) {
      return category as Category;
    }
  }

  return null;
};
