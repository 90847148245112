import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CarReader } from '@ipld/car';
import { IFile } from 'gdgateway-client/lib/es5/process/types';
import { downloadFile, getCidLevelByFileSize } from 'gdgateway-client/lib/es5';

import GhostLoader from 'components/GhostLoader';
import { getDownloadOTT } from 'store/home/effects';
import { actionsType } from 'config/actions-options';
import { getFileCids } from 'store/home/effects/file/get-file-cid';
import { sendFileViewStatistic } from 'store/home/effects/statistic/file-statistic-effect';

interface TxtViewerProps {
  file: IFile;
  setEntityType: (value: string) => void;
}

const TxtViewer = ({ file, setEntityType }: TxtViewerProps) => {
  const [loading, setLoading] = useState(true);
  const { t: accountT } = useTranslation('account');

  useEffect(() => {
    getPreview();
  }, []);

  const getPreview = async () => {
    try {
      const {
        data: {
          jwt_ott,
          user_tokens: { token: oneTimeToken },
          gateway,
          upload_chunk_size,
        },
      } = await getDownloadOTT([{ slug: file.slug }], actionsType.VIEWED);

      let cidData;
      if (file.is_on_storage_provider) {
        const level = getCidLevelByFileSize(file);
        cidData = await getFileCids({ slug: file.slug, level });
      }
      await sendFileViewStatistic(file.slug);

      const controller = new AbortController();
      const blob = await downloadFile({
        file,
        oneTimeToken,
        jwtOneTimeToken: jwt_ott,
        endpoint: gateway.url,
        isEncrypted: false,
        carReader: CarReader,
        uploadChunkSize:
          upload_chunk_size[file.slug] || gateway.upload_chunk_size,
        cidData,
        signal: controller.signal,
      });
      if (blob) {
        const realBlob = new Blob([blob]);
        const text = await realBlob.text();
        displayPreview(text);
      } else {
        setEntityType('');
        setLoading(false);
      }
    } catch {
      setEntityType('');
      setLoading(false);
    }
  };

  const displayPreview = (text: any) => {
    const previewContainer = document.getElementById('preview-container');
    if (previewContainer) {
      const lines = text.split('\n').slice(0, 10);
      const previewText = lines.join('<br>');
      previewContainer.innerHTML = previewText;
    } else {
      setEntityType('');
    }
    setLoading(false);
  };

  return (
    <div className="file-view__txt">
      <div
        id="preview-container"
        style={{ display: loading ? 'none' : 'block' }}
      ></div>
      {loading && (
        <div className="loader">
          <GhostLoader
            texts={[
              accountT('convertModal.loading'),
              accountT('convertModal.pleaseWait'),
            ]}
          />
        </div>
      )}
    </div>
  );
};

export default TxtViewer;
