import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { onCloseAllModal } from 'features/modals/modal-slice';
import { IRootState } from 'store/root-store';

import CreateRenameModal from 'containers/main/NeyraDriveContainer/components/ContentDrive/CreateRename';
import CreateDocumentModal from 'containers/main/NeyraDriveContainer/components/ContentDrive/CreateDocument';
import EncryptFileModal from 'containers/main/NeyraDriveContainer/components/ContentDrive/Encrypt';
import ConvertModal from 'containers/main/NeyraDriveContainer/components/ContentDrive/Convert';
import MoveModal from 'containers/main/NeyraDriveContainer/components/ContentDrive/Move';
import PreviewModal from 'containers/main/NeyraDriveContainer/components/ContentDrive/Preview';

import { isEmpty } from 'lodash';

type IProps = {
  children: React.ReactElement;
};

const Content = ({ children }: IProps) => {
  const modalState =
    useSelector((store: IRootState): any => store.modals) || {};
  const isEncryptOpen = useSelector(
    (store: IRootState): any => store.home.encrypt.openModal
  );
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const closeList: string[] = [];
    Object.keys(modalState).forEach((key: any) => {
      if (modalState[key] === true) closeList.push(key);
    });
    if (!isEmpty(closeList)) {
      dispatch(onCloseAllModal());
    }
  }, [history.location]);

  return (
    <>
      {modalState.createFolderOpen && (
        <CreateRenameModal entity={modalState.additionalData} />
      )}

      {modalState.createDocumentOpen && (
        <CreateDocumentModal entity={modalState.additionalData} />
      )}

      {isEncryptOpen && <EncryptFileModal isOpen={isEncryptOpen} />}

      {modalState.convertModal && (
        <ConvertModal entity={modalState.additionalData} />
      )}

      {modalState.moveModal && <MoveModal entity={modalState.additionalData} />}

      {modalState.previewModal && (
        <PreviewModal entity={modalState.additionalData} />
      )}

      {children}
    </>
  );
};

export default Content;
