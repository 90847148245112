//Refactoring №3
import axios from 'axios';

import {
  fileRemoveFailure,
  fileRemoveSuccess,
} from '../../actions/file/remove-file-from-trash.actions';
import { selectedFilesRemove } from '../../actions/selected-files-folders.actions';
import { getFilesRemove } from '../../actions';
import { getOneTimeTokenForDeleting } from 'store/home/effects';
import {
  FileCountActionType,
  workspaceUpdateFileCountByType,
} from 'features/workspaceSettings/workspaceSettingsSlice';

export const entityClearAllEffect = async (
  dispatch: (cb: any) => void,
  files: any
) => {
  const fileSlugs: string[] = [];

  files.forEach((file: any) => {
    const slug = file.slug;
    fileSlugs.push(slug);
  });
  const {
    data: {
      jwt_ott,
      user_tokens: { token: oneTimeToken },
      gateway,
    },
  } = await getOneTimeTokenForDeleting();

  const url = `${gateway.url}/trash/all`;

  return axios
    .create({
      headers: {
        'one-time-token': oneTimeToken,
        'Content-Type': 'application/json',
      },
    })
    .delete(url, { data: { token: jwt_ott } })
    .then(() => {
      dispatch(fileRemoveSuccess());
      dispatch(selectedFilesRemove(files));
      dispatch(getFilesRemove(files));
      dispatch(
        workspaceUpdateFileCountByType({
          actionType: FileCountActionType.DELETE_PERMANENTLY_ALL,
        })
      );
    })
    .catch(() => {
      dispatch(fileRemoveFailure());
    });
};
