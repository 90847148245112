//Refactoring №3
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { isEmpty } from 'ramda';
import CN from 'classnames';

import {
  handleEncryptModal,
  needEncryptFile,
  handleUploadDropedFiles,
} from 'store/home/actions';
import MainContext from 'store/main/context/main-context';
import UserContext from 'store/home/contexts/user-context';
import EntitySettings from 'store/home/contexts/entity-settings';
import fileFolderActionHandlerEffect from 'store/home/effects/entity-actions/entity-action-handler.effect';
import { isMobile } from 'utils/mobile';

import {
  selectIsMultisigActivated,
  selectIsMultisigPartisipant,
} from 'features/app';
import actionsOptions from 'config/actions-options';
import {
  handleCreateDocumentModal,
  handleCreateFolderModal,
  handleLinkModal,
  handleDeletAllModal,
  handleUpload,
  selectOpenUpload,
  selectRecordModal,
  handleRecordModal,
} from 'features/modals/modal-slice';
import { canUserEncryptFile } from 'utils/canUserEncryptFile.js';
import useNotification from 'utils/hooks/use-notification';

import { EncryptModal } from '../EncryptModal';
import AutocompleteSearch from 'containers/header/header-container/components/AutocompleteSearchV2';
import { entitiesTypes } from 'config/entities-types';

import { Icon } from './Icon';

import s from './style.module.scss';

interface IActionButtonsProps {
  selectedFiles: Array<any>;
  isGuest: boolean;
  errorCode: string;
  getUserInfoEffect: () => void;
  downloadEntity: () => void;
  deleteEntity: () => void;
  isSelectedEntity: boolean;
  isEncryptOpen: boolean;
  uploadDropedFiles: boolean;
  onOpenVideoRecord: () => void;
}

const ActionButtons = ({
  searchOn,
  setSearchOn,
  actionBtnRef,
  uploadDropedFiles,
  selectedFiles,
  isGuest,
  errorCode,
  isEncryptOpen,
  downloadEntity,
  deleteEntity,
  onOpenVideoRecord,
}: IActionButtonsProps) => {
  const { addNotification } = useNotification();
  const { user } = useContext(UserContext);
  const {
    state: {
      isDeletedPage,
      isActionPage,
      isRecentPage,
      pageUrl,
      trashHeaderActionHandler,
      folder,
      workspace: {
        name: workspaceName,
        time_limits = {},
        slug,
        id,
        ...workspace
      },
    },
    dropUploaderRef,
  } = useContext<{
    state: {
      isDeletedPage?: boolean;
      isActionPage?: boolean;
      isRecentPage?: boolean;
      isDocumentPage?: boolean;
      isVaultPage?: boolean;
      isFilteredFilesPage?: boolean;
      trashHeaderActionHandler?: any;
      workspace: any;
    };
    dropUploaderRef: any;
  }>(MainContext);
  const isTokenizedFolder = folder?.entry_groups?.some(
    (item) => item.is_tokenized
  );
  const openUploadState = useSelector(selectOpenUpload);
  const openRecordState = useSelector(selectRecordModal);
  const isMultisigActivated = useSelector(selectIsMultisigActivated);
  const isMultisigPartisipant = useSelector(selectIsMultisigPartisipant);
  const entitySettings = useContext(EntitySettings);
  const filesCount = useSelector((state: any) => state.home?.getFiles?.count);
  const dispatch = useDispatch();
  const { t } = useTranslation('hint');
  const { t: ownerTranslation } = useTranslation('owner');
  const includesSelectedFolder = selectedFiles.some(
    (item) => item?.type === entitiesTypes.folder.id
  );

  const canBeDownLoadedAndDeleted = !isMultisigActivated
    ? (selectedFiles.length === 1 && !entitySettings.entity) ||
      (entitySettings.entity && selectedFiles.length === 0)
    : isMultisigPartisipant;
  const isAnySelectedDelete =
    (selectedFiles.length > 0 || entitySettings.entity) &&
    (!isMultisigActivated ? true : isMultisigPartisipant);

  const isMainPage = '/main' === pageUrl;

  useEffect(() => {
    if (openRecordState) {
      onOpenVideoRecord();
      dispatch(handleRecordModal(false));
    }
  }, [openRecordState]);

  useEffect(() => {
    if (openUploadState) {
      onAddFile();
      dispatch(handleUpload(false));
    }
  }, [openUploadState]);

  const handleCloseEncrypt = () => {
    dispatch(handleEncryptModal(false));
    dispatch(handleUploadDropedFiles(false));
  };

  const restoreEntity = () => {
    const { restoreFromTrash } = actionsOptions;
    if (isEmpty(selectedFiles) && isEmpty(entitySettings.entity)) {
      return;
    }

    const entities = !isEmpty(selectedFiles)
      ? selectedFiles
      : entitySettings.entity;
    trashHeaderActionHandler(entities, restoreFromTrash);
  };

  const deletePermanently = () => {
    const { removeFromTrash } = actionsOptions;
    const entities = !isEmpty(selectedFiles)
      ? selectedFiles
      : entitySettings.entity;
    trashHeaderActionHandler(entities, removeFromTrash);
  };

  const onAddFile = async () => {
    const encryptFiles = workspace?.encrypt_files_on_upload;
    const modalVisibility = workspace?.show_encrypt_modal;

    dispatch(handleEncryptModal(modalVisibility));

    if (!modalVisibility && !encryptFiles) {
      dispatch(needEncryptFile(false));
      onAddFileEncrypt();
    }
    if (!modalVisibility && encryptFiles) {
      const canEncrypt = await canUserEncryptFile(user?.user_public_addresses);

      if (!canEncrypt?.error) {
        dispatch(needEncryptFile(true));
        onAddFileEncrypt();
      } else {
        addNotification(canEncrypt?.message, 'alert');
      }
    }
  };

  const onAddFileEncrypt = () => {
    if (uploadDropedFiles) {
      handleCloseEncrypt();
    } else if (dropUploaderRef?.current && !errorCode) {
      dropUploaderRef.current.open();
    }
  };

  const showCreateDocumentModal = () => {
    dispatch(handleCreateDocumentModal(true));
  };

  const handleCreateFolder = () => {
    dispatch(handleCreateFolderModal(true));
  };

  const handleCreateLink = () => {
    dispatch(handleLinkModal({ isOpen: true }));
  };

  const handleDeleatAllModal = () => {
    dispatch(handleDeletAllModal({ isOpen: true }));
  };

  const handleShare = () => {
    const file = entitySettings.entity || selectedFiles[0];
    dispatch(
      fileFolderActionHandlerEffect(file, actionsOptions.accessPreferences, {
        skipGetData: true,
      })
    );
  };

  // const handleCreatePassword = () => {
  //   dispatch(handleVaultModal({ isOpen: true }));
  // }

  const actionHandler = () => {
    switch (pageUrl) {
      case '/memos':
        showCreateDocumentModal();
        break;
      case '/links':
        handleCreateLink();
        break;

      default:
        onAddFile();
    }
  };

  return (
    <div
      ref={actionBtnRef}
      className={s.headerActionsBtnWrapper}
      data-test={`main-header_action_buttons`}
    >
      {isDeletedPage ? (
        <>
          <AutocompleteSearch
            setSearchOn={setSearchOn}
            searchOn={searchOn}
            dispatch={dispatch}
          />
          {isAnySelectedDelete && (
            <button
              type="button"
              disabled={!isAnySelectedDelete}
              className={CN(
                s.uploadBtn,
                !isAnySelectedDelete && s.uploadBtnDisabled
              )}
              onClick={restoreEntity}
              data-test={`main-header_restore_button`}
              title={t('restore')}
            >
              <Icon type="restore" />
            </button>
          )}
          {isAnySelectedDelete && (
            <button
              disabled={!isAnySelectedDelete}
              className={CN(
                s.uploadBtn,
                !isAnySelectedDelete && s.uploadBtnDisabled
              )}
              type="button"
              onClick={deletePermanently}
              data-test={`main-header_delete_button`}
              title={t('delete')}
            >
              <Icon type="delete" />
            </button>
          )}
          <button
            disabled={filesCount === 0}
            className={CN(
              s.actionBtnDelete,
              filesCount === 0 && s.uploadBtnDisabled
            )}
            type="button"
            onClick={handleDeleatAllModal}
            data-test={`main-header_clear_button`}
            title={ownerTranslation('deleted.clear')}
          >
            {ownerTranslation('deleted.clear')}
          </button>
        </>
      ) : isGuest ? (
        <>
          <AutocompleteSearch
            setSearchOn={setSearchOn}
            searchOn={searchOn}
            dispatch={dispatch}
          />
          {canBeDownLoadedAndDeleted && !includesSelectedFolder && (
            <button
              className={s.extraBtn}
              disabled={isMultisigActivated && !isMultisigPartisipant}
              type="button"
              onClick={downloadEntity}
              data-test={`main-header_action-page_download_button`}
              title={t('download')}
            >
              <Icon type="download" />
            </button>
          )}
          {isAnySelectedDelete && (
            <button
              className={s.extraBtn}
              disabled={isMultisigActivated && !isMultisigPartisipant}
              type="button"
              onClick={deleteEntity}
              data-test={`main-header_action-page_delete_button`}
              title={t('moveDelete')}
            >
              <Icon type="delete" />
            </button>
          )}
        </>
      ) : isActionPage || isRecentPage ? null : (
        <>
          {isMobile && (
            <EncryptModal
              onClose={handleCloseEncrypt}
              isOpen={isEncryptOpen}
              callback={onAddFileEncrypt}
            />
          )}
          <button
            className={s.uploadBtn}
            disabled={
              (isMultisigActivated && !isMultisigPartisipant) ||
              isTokenizedFolder
            }
            type="button"
            onClick={actionHandler}
            data-test={`main-header_action-page_upload_button`}
            title={t('upload')}
          >
            <Icon type="add" />
          </button>
          <AutocompleteSearch
            setSearchOn={setSearchOn}
            searchOn={searchOn}
            dispatch={dispatch}
          />
          {isMainPage && (
            <button
              className={s.actionBtn}
              disabled={isMultisigActivated && !isMultisigPartisipant}
              type="button"
              title={t('folder')}
              onClick={handleCreateFolder}
            >
              <Icon type="folder" />
            </button>
          )}
          {/* {!canBeDownLoadedAndDeleted && !isBetaEnviroment && (
            <button
              className={s.actionBtn}
              disabled={isMultisigActivated && !isMultisigPartisipant}
              type="button"
              onClick={handleCreatePassword}
              data-test={`main-header_action-page_add_button`}
              title={t('Create password')}
              >
              <Icon type='password' />
            </button>
            )} */}
          {/* { !canBeDownLoadedAndDeleted && !isBetaEnviroment && (
              <button
                className={s.actionBtn}
                disabled={isMultisigActivated && !isMultisigPartisipant}
                type="button"
                onClick={showCreateDocumentModal}
                data-test={`main-header_action-page_add_button`}
                title={t('Create documment')}
              >
                <Icon type='document' />
              </button> */}
          {/* )}
            {!canBeDownLoadedAndDeleted && ( 
              <button
                className={s.actionBtn}
                disabled={isMultisigActivated && !isMultisigPartisipant}
                type="button"
                onClick={handleCreateLink}
                data-test={`main-header_action-page_add_button`}
                title={t('Create link')}
              >
                <Icon type='link' />
              </button>
            )} */}
          {canBeDownLoadedAndDeleted && !includesSelectedFolder && (
            <button
              className={s.extraBtn}
              disabled={isMultisigActivated && !isMultisigPartisipant}
              type="button"
              onClick={downloadEntity}
              data-test={`main-header_action-page_download_button`}
              title={t('download')}
            >
              <Icon type="download" />
            </button>
          )}
          {isMainPage && (selectedFiles.length === 1 || entitySettings.entity) && (
            <button
              className={s.extraBtn}
              disabled={isMultisigActivated && !isMultisigPartisipant}
              type="button"
              onClick={handleShare}
              data-test={`main-header_action-page_share_button`}
              title="Share"
            >
              <Icon type="share" />
            </button>
          )}
          {isAnySelectedDelete && (
            <button
              className={s.extraBtn}
              disabled={isMultisigActivated && !isMultisigPartisipant}
              type="button"
              onClick={deleteEntity}
              data-test={`main-header_action-page_move_delete_button`}
              title={t('moveDelete')}
            >
              <Icon type="delete" />
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default ActionButtons;
