import React from 'react';
import Modal from 'react-modal';
import CN from 'classnames';

import CloseIcon from 'components/svg/close';
import styles from './styles.module.scss';

export const MODAL_CONTAINER_ID = 'modal-container';

type IProps = {
  isOpen: boolean;
  title: string;
  children: React.ReactElement;
  onClose: () => void;
  actions?: React.ReactElement;
};

const TemplateModal = ({
  title,
  children,
  actions,
  onClose,
  isOpen = true,
}: IProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName={styles.overlay}
      className={CN(styles.templateContainer, actions && styles.noPadding)}
      ariaHideApp={false}
      parentSelector={() =>
        document.getElementById(MODAL_CONTAINER_ID) ?? document.body
      }
    >
      <div className={CN(styles.header, actions && styles.actionHeader)}>
        <h1 className={styles.title}>{title}</h1>
        {actions ? (
          <div className={styles.actionContent}>{actions}</div>
        ) : (
          <button className={styles.close} onClick={onClose}>
            <CloseIcon />
          </button>
        )}
      </div>
      <div className={styles.content}>{children}</div>
    </Modal>
  );
};

export default TemplateModal;
