// Utils
import authRequest from 'utils/request/auth-request';
import { API_TRASH } from 'constants/api-urls';

// Store
import {
  fileRestoreSuccess,
  fileRestoreFailure,
} from '../../actions/file/restore-file.actions';
import { selectedFilesRemove } from '../../actions/selected-files-folders.actions';

import { getFilesRemove } from '../../actions/files/get-files.actions';
import { getWorkspaceFileCount } from 'store/workspace/effects';
import { workspaceSetFileCountByType } from 'features/workspaceSettings/workspaceSettingsSlice';

export default async (dispatch, files, aditinalData) => {
  const url = `${API_TRASH}/multiply/restore`;
  await authRequest
    .put(
      url,
      files.map((file) => file.slug)
    )
    .then(() => {
      dispatch(getFilesRemove(files));
      dispatch(selectedFilesRemove(files));
      dispatch(fileRestoreSuccess());
      aditinalData?.successFunction();
      getWorkspaceFileCount().then((data) => {
        dispatch(workspaceSetFileCountByType(data));
      });
    })
    .catch(() => {
      dispatch(fileRestoreFailure());
    });
};
