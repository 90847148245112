// Refactoring №3
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { read, utils } from 'xlsx';
import GhostLoader from 'components/GhostLoader';
import { getPreviewFileBlob } from 'store/home/effects/file/get-preview-file-blob';

import s from './style.module.scss';

const ExcelViewer = ({ file, setEntityType }) => {
  const [htmlString, setHtmlString] = useState('');
  const [loading, setLoading] = useState(true);
  const { t: accountT } = useTranslation('account');

  useEffect(() => {
    getPreviewFileBlob({ file })
      .then((blobData) => {
        if (blobData) {
          const reader = new FileReader();

          reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const htmlString = utils.sheet_to_html(worksheet);

            setHtmlString(htmlString);
            setLoading(false);
          };

          reader.readAsArrayBuffer(blobData);
        } else {
          setLoading(false);
          setEntityType('');
        }
      })
      .catch(() => {
        setLoading(false);
        setEntityType('');
      });
  }, [file]);

  return (
    <div className={s.container}>
      {htmlString && (
        <div className={s.excelViewer}>
          <div dangerouslySetInnerHTML={{ __html: htmlString }} />
        </div>
      )}
      {loading && (
        <div className="loader">
          <GhostLoader
            texts={[
              accountT('convertModal.loading'),
              accountT('convertModal.pleaseWait'),
            ]}
          />
        </div>
      )}
    </div>
  );
};

export default ExcelViewer;
