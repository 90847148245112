import {
  imageMediaTypes,
  imagesWithoutPreview,
} from 'config/image-file-extensions';
import {
  audioMediaTypes,
  audioWithoutPreview,
  audioWithoutPreviewIOS,
  audioWithoutPreviewOther,
} from 'config/audio-file-extensions';
import {
  videoMediaExtentionPreview,
  videoWithoutPreview,
  videoWithoutPreviewIOS,
} from 'config/video-file-extensions';
import {
  docMediaTypesContent,
  docMediaWithoutPreview,
} from 'config/docs-file-extensions';
import { isiOSPlatform } from 'utils/browser';

export const getPreviewFileType = (file) => {
  let entityFileType = '';
  const isIOS = isiOSPlatform();

  if (!file || !file.mime) return entityFileType;

  if (
    videoMediaExtentionPreview.includes(file.extension) &&
    !videoWithoutPreview.includes(file.extension) &&
    !(isIOS && videoWithoutPreviewIOS.includes(file.extension))
  ) {
    return 'video';
  }

  if (
    audioMediaTypes.includes(file.mime) &&
    !audioWithoutPreview.includes(`.${file.extension}`) &&
    !(isIOS && audioWithoutPreviewIOS.includes(`.${file.extension}`)) &&
    !(!isIOS && audioWithoutPreviewOther.includes(`.${file.extension}`))
  ) {
    return 'audio';
  }

  if (file?.is_clientside_encrypted) {
    return 'encrypt';
  } else {
    if (file.mime === 'application/pdf') {
      entityFileType = 'pdf';
    } else if (
      docMediaTypesContent.includes(file.mime) ||
      docMediaWithoutPreview.includes(file.extension)
    ) {
      entityFileType = 'document';
      if (docMediaWithoutPreview.includes(file.extension)) {
        entityFileType = '';
      }
    } else if (imageMediaTypes.includes(file.mime)) {
      if (imagesWithoutPreview.includes(`.${file.extension}`)) {
        entityFileType = '';
      } else {
        entityFileType = 'img';
      }
    } else if (file.extension === 'xlsx' || file.extension === 'xls') {
      entityFileType = 'xlsx';
    } else if (file.mime === 'text/plain') {
      entityFileType = 'txt';
    }
  }

  return entityFileType;
};
